
import { Vue, Options, prop } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import { formatDate, formatPrice } from "@/app/infrastructures/misc/Utils";

class Props {
  detailData = prop<any>({
    type: Object,
    required: true,
  });
}

@Options({
  components: {
    Title,
  },
  props: {
    detailData: {
      type: Object,
      required: true,
    },
  },
})
export default class DetailContractInformation extends Vue.with(Props) {
  convertDate(date: string) {
    return formatDate(date);
  }

  convertCurrency(value: number) {
    return formatPrice(value);
  }
}
