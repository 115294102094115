
import { Options, Vue } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import { LocationController } from "@/app/ui/controllers/LocationController";
import { Hub } from "@/domain/entities/Hub";
import { checkRolePermission } from "@/app/infrastructures/misc/Utils";
import { PARTNER_MANAGEMENT } from "@/app/infrastructures/misc/RolePermission";

@Options({
  components: {
    DetailLayout,
    Title
  },
  computed: {
    isEditAble() {
      return checkRolePermission(PARTNER_MANAGEMENT.EDIT);
    }
  }
})

export default class Detail extends Vue {
  get hubData() {
    //dummy data
    return new Hub({
      hubId: this.hubNo,
      hubName: "Hub 1",
      hubCode: "HP1",
      originCity: "Jakarta",
      consolidator: "PT Lion Express",
      hubAddress: "Jl. Kedoya Agave Raya No.55, Kedoya Sel., Kec. Kb. Jeruk, Kota Jakarta Barat",
      hubDistrict: "Kebon Jeruk",
      hubPhoneNumber: "081234567890",
      hubContactPersonName: "John Dhoe",
      hubContactPersonPhoneNumber: "081234567890",
      hubContactPersonEmail: "johndhoe@mail.com",
      status: "Aktif",
      isActive: true
    });
  }

  get hubDataStatus() {
    //dummy data
    return this.hubData.isActive ? "Aktif" : "Tidak Aktif";
  }

  get hubNo(): any {
    return decodeURIComponent(String(this.$route.params?.id));
  }

  get isLoading() {
    return LocationController.isLoading;
  }

  get isError() {
    return LocationController.isError;
  }

  get errorCause() {
    return LocationController.isErrorCauseCity;
  }

  // route navigatiion
  goBack() {
    this.$router.push(`/partner/hub`);
  }
  goToEdit() {
    this.$router.push(`/partner/hub/${this.hubNo}/edit`);
  }
}
