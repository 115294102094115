import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "w-full mt-5" }
const _hoisted_2 = { class: "grid grid-cols-5 gap-3" }
const _hoisted_3 = { class: "flex text-red-lp-100 gap-1 mt-2" }
const _hoisted_4 = { class: "text-12px" }
const _hoisted_5 = { class: "flex text-red-lp-100 gap-1 mt-2" }
const _hoisted_6 = { class: "text-12px" }
const _hoisted_7 = { class: "flex text-red-lp-100 gap-1 mt-2" }
const _hoisted_8 = { class: "text-12px" }
const _hoisted_9 = { class: "flex text-red-lp-100 gap-1 mt-2" }
const _hoisted_10 = { class: "text-12px" }
const _hoisted_11 = { class: "text-12px text-gray-lp-600 mt-3" }
const _hoisted_12 = { class: "text-12px text-gray-lp-600" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UploadImageTiny = _resolveComponent("UploadImageTiny")!
  const _component_Icons = _resolveComponent("Icons")!
  const _component_data_wrapper = _resolveComponent("data-wrapper")!

  return (_openBlock(), _createBlock("div", null, [
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_data_wrapper, {
        label: _ctx.$t('partnerManagement.fileAttachment'),
        asterisk: ""
      }, {
        default: _withCtx(() => [
          _createVNode("div", _hoisted_2, [
            (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.proofPhotos, (photo, index) => {
              return (_openBlock(), _createBlock("div", { key: index }, [
                _createVNode(_component_UploadImageTiny, {
                  title: photo,
                  modelValue: _ctx.attachmentImage[index],
                  "onUpdate:modelValue": ($event: any) => (_ctx.attachmentImage[index] = $event),
                  defaultImage: _ctx.storageImages[index],
                  maxSize: 3,
                  multipleIndex: index,
                  onUpload: _ctx.upload,
                  onRemove: _ctx.remove
                }, null, 8, ["title", "modelValue", "onUpdate:modelValue", "defaultImage", "multipleIndex", "onUpload", "onRemove"])
              ]))
            }), 128))
          ]),
          _withDirectives(_createVNode("div", _hoisted_3, [
            _createVNode(_component_Icons, {
              name: "warning-triangle",
              class: " w-4"
            }),
            _createVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('partnerManagement.validate.minimumUpload')), 1)
          ], 512), [
            [_vShow, _ctx.validateLimitUpload]
          ]),
          _withDirectives(_createVNode("div", _hoisted_5, [
            _createVNode(_component_Icons, {
              name: "warning-triangle",
              class: " w-4"
            }),
            _createVNode("p", _hoisted_6, _toDisplayString(_ctx.errorMessage), 1)
          ], 512), [
            [_vShow, _ctx.isError]
          ]),
          _withDirectives(_createVNode("div", _hoisted_7, [
            _createVNode(_component_Icons, {
              name: "warning-triangle",
              class: " w-4"
            }),
            _createVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('partnerManagement.validate.processUpload')), 1)
          ], 512), [
            [_vShow, _ctx.isLoading]
          ]),
          _withDirectives(_createVNode("div", _hoisted_9, [
            _createVNode(_component_Icons, {
              name: "warning-triangle",
              class: " w-4"
            }),
            _createVNode("p", _hoisted_10, _toDisplayString(_ctx.$t('partnerManagement.validate.processDelete')), 1)
          ], 512), [
            [_vShow, _ctx.isLoadingDelete]
          ]),
          _createVNode("p", _hoisted_11, _toDisplayString(_ctx.$t('partnerManagement.description.fileOne')), 1),
          _createVNode("p", _hoisted_12, _toDisplayString(_ctx.$t('partnerManagement.description.fileTwo')), 1)
        ]),
        _: 1
      }, 8, ["label"])
    ])
  ]))
}