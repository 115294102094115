
import { Vue, Options, prop } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import { BeneficiaryAccountController } from "@/app/ui/controllers/BeneficiaryAccountController";

class Props {
  detailData = prop<any>({
    type: Object,
    required: true
  });
}

@Options({
  components: {
    Title
  }
})
export default class DetailBankInformation extends Vue.with(Props) {
  created() {
    this.getBeneficiaryAccount();
  }

  get partnerType() {
    const type = this.$route.meta.type as string;
    const accountType: any = {
      "consolidator": "console",
      "sub-consolidator": "sub-console"
    }

    return accountType[type] ?? "pos";
  }

  getBeneficiaryAccount() {
    BeneficiaryAccountController.getBeneficiaryAccList({
      page: 1,
      limit: 1,
      bankAccType: "beneficiary",
      actortype: this.partnerType,
      actorId: this.detailData.partnerId
    });
  }

  get beneficiaryDetail() {
    return BeneficiaryAccountController.beneficiaryList.data;
  }
}
