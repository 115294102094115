import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen relative" }
const _hoisted_2 = { class: "flex w-9/12 gap-x-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Search = _resolveComponent("Search")!
  const _component_AdvancedFilter = _resolveComponent("AdvancedFilter")!
  const _component_LpButton = _resolveComponent("LpButton")!
  const _component_Header = _resolveComponent("Header")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_TableV2 = _resolveComponent("TableV2")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Header, { class: "items-center" }, {
      default: _withCtx(() => [
        _createVNode("div", _hoisted_2, [
          _createVNode(_component_Search, {
            class: "w-4/12",
            onInput: _ctx.onSearch,
            onClear: _ctx.clearSearch,
            value: _ctx.searchValue,
            placeholder: "Cari nama/kode hub"
          }, null, 8, ["onInput", "onClear", "value"]),
          _createVNode(_component_AdvancedFilter, {
            modelValue: _ctx.advancedFilter,
            "onUpdate:modelValue": _ctx.onUpdateAdvancedFilter,
            onFilter: _ctx.requestFilter,
            isHubList: ""
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onFilter"])
        ]),
        (_ctx.isCreateAble)
          ? (_openBlock(), _createBlock(_component_LpButton, {
              key: 0,
              onClick: _ctx.goToCreate,
              title: "Buat Hub",
              textColor: "white"
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (!_ctx.dataTable.length && !_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_EmptyState, {
          key: 0,
          data: "data Hub"
        }))
      : (_openBlock(), _createBlock(_component_TableV2, {
          key: 1,
          pagination: _ctx.pagination,
          "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => (_ctx.pagination = $event)),
          loading: _ctx.isLoading,
          columns: _ctx.columns,
          data: _ctx.dataTable,
          class: "my-2",
          onRequest: _ctx.onRequest,
          onTryAgain: _ctx.onRequest,
          onClick: _ctx.onClickRow,
          errorCause: _ctx.errorCause,
          pinnedSubstractHeight: "290px",
          paginationStyle: "v3",
          isDetailAble: _ctx.isDetailAble
        }, null, 8, ["pagination", "loading", "columns", "data", "onRequest", "onTryAgain", "onClick", "errorCause", "isDetailAble"]))
  ]))
}