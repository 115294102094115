import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "space-y-6 ml-4" }
const _hoisted_2 = { class: "flex gap-y-6 flex-row flex-wrap" }
const _hoisted_3 = { class: "w-6/12 space-y-6" }
const _hoisted_4 = { class: "w-6/12 space-y-6" }
const _hoisted_5 = { class: "w-6/12 space-y-6" }
const _hoisted_6 = { class: "w-6/12 space-y-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!
  const _component_ImagePreview = _resolveComponent("ImagePreview")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_Title, { label: "Informasi Tambahan" }),
      _createVNode("div", _hoisted_2, [
        _createVNode("div", _hoisted_3, [
          _createVNode(_component_DataWrapper, {
            class: "w-full",
            label: "Saldo Deposit",
            value: _ctx.convertCurrency(_ctx.detailData.partnerPosSaldoDeposit)
          }, null, 8, ["value"])
        ]),
        _createVNode("div", _hoisted_4, [
          _createVNode(_component_DataWrapper, {
            class: "w-full",
            label: "Top Up Pertama",
            value: _ctx.convertDate(_ctx.detailData.partnerPosFirstDateTopup)
          }, null, 8, ["value"])
        ]),
        _createVNode("div", _hoisted_5, [
          _createVNode(_component_DataWrapper, {
            class: "w-full",
            label: "Transaksi Pertama",
            value: _ctx.convertDate(_ctx.detailData.partnerPosFirstTransaction)
          }, null, 8, ["value"])
        ]),
        _createVNode("div", _hoisted_6, [
          _createVNode(_component_DataWrapper, {
            class: "w-full",
            label: "Pick Up Manifest Pertama",
            value: _ctx.convertDate(_ctx.detailData.partnerPosPickupManifest)
          }, null, 8, ["value"])
        ])
      ])
    ]),
    _createVNode(_component_ImagePreview, {
      modelValue: _ctx.photoPreview,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.photoPreview = $event)),
      data: _ctx.photoPreviews,
      image: _ctx.photo
    }, null, 8, ["modelValue", "data", "image"])
  ], 64))
}