import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "space-y-6 ml-4" }
const _hoisted_2 = { class: "flex gap-y-6 flex-row flex-wrap" }
const _hoisted_3 = { class: "flex gap-y-6 flex-row flex-wrap" }
const _hoisted_4 = {
  key: 0,
  class: "flex gap-y-6 flex-row flex-wrap"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Title, { label: "Detail Partner" }),
    _createVNode("div", _hoisted_2, [
      (_ctx.detailData.partnerType.toLowerCase() === 'console')
        ? (_openBlock(), _createBlock(_component_DataWrapper, {
            key: 0,
            class: "w-6/12",
            label: "Kode Consolidator",
            value: _ctx.detailData.partnerCode
          }, null, 8, ["value"]))
        : (_ctx.detailData.partnerType.toLowerCase() === 'sub-console')
          ? (_openBlock(), _createBlock(_component_DataWrapper, {
              key: 1,
              class: "w-6/12",
              label: "Nama Sub Consolidator",
              value: _ctx.detailData.partnerName
            }, null, 8, ["value"]))
          : (_openBlock(), _createBlock(_component_DataWrapper, {
              key: 2,
              class: "w-6/12",
              label: "Nama POS",
              value: _ctx.detailData.partnerName
            }, null, 8, ["value"])),
      _createVNode(_component_DataWrapper, {
        class: "w-6/12",
        label: "Kode Elexys",
        value: _ctx.detailData.partnerElexysCode
      }, null, 8, ["value"]),
      (_ctx.detailData.partnerType.toLowerCase() === 'console')
        ? (_openBlock(), _createBlock(_component_DataWrapper, {
            key: 3,
            class: "w-6/12",
            label: "Nama Consolidator",
            value: _ctx.detailData.partnerName
          }, null, 8, ["value"]))
        : (_ctx.detailData.partnerType.toLowerCase() === 'sub-console')
          ? (_openBlock(), _createBlock(_component_DataWrapper, {
              key: 4,
              class: "w-6/12",
              label: "Kode Sub Consolidator",
              value: _ctx.detailData.partnerCode
            }, null, 8, ["value"]))
          : (_openBlock(), _createBlock(_component_DataWrapper, {
              key: 5,
              class: "w-full",
              label: "Kode POS",
              value: _ctx.detailData.partnerCode
            }, null, 8, ["value"])),
      (_ctx.detailData.partnerType.toLowerCase().includes('console'))
        ? (_openBlock(), _createBlock(_component_DataWrapper, {
            key: 6,
            class: "w-6/12",
            label: "POS Pengembalian STT",
            value: _ctx.detailData.partnerPosReverseJourney,
            tooltipDescription: "Akun untuk booking STT yang dikembalikan ke pengirim atau diteruskan ke alamat yang benar",
            optional: ""
          }, null, 8, ["value"]))
        : _createCommentVNode("", true),
      _createVNode(_component_DataWrapper, {
        class: "w-9/12",
        label: "Alamat",
        value: _ctx.detailData.partnerAddress
      }, null, 8, ["value"]),
      (_ctx.detailData.partnerType.toLowerCase() === 'pos')
        ? (_openBlock(), _createBlock(_component_DataWrapper, {
            key: 7,
            class: "w-9/12",
            label: "Kecamatan, Kota",
            value: _ctx.detailData.partnerDistrict
          }, null, 8, ["value"]))
        : (_openBlock(), _createBlock(_component_DataWrapper, {
            key: 8,
            class: "w-6/12",
            label: "Kecamatan, Kota",
            value: _ctx.detailData.partnerDistrict
          }, null, 8, ["value"])),
      (_ctx.detailData.partnerType.toLowerCase() === 'console')
        ? (_openBlock(), _createBlock(_component_DataWrapper, {
            key: 9,
            class: "w-6/12",
            label: "Kota Tambahan",
            value: _ctx.additionalCities,
            isTooltip: "",
            icon: "info",
            iconActive: "info-red",
            tooltipTitle: "Kota Tambahan",
            tooltipDescription: "Masukan kota tambahan yang ingin ditangani. Partner dapat menangani lebih dari satu (1) kota."
          }, null, 8, ["value"]))
        : _createCommentVNode("", true),
      (_ctx.detailData.partnerType.toLowerCase() === 'console')
        ? (_openBlock(), _createBlock(_component_DataWrapper, {
            key: 10,
            class: "w-6/12",
            label: "No. Telepon",
            value: _ctx.detailData.partnerPhoneNumber
          }, null, 8, ["value"]))
        : _createCommentVNode("", true),
      (_ctx.detailData.partnerType.toLowerCase() === 'console')
        ? (_openBlock(), _createBlock(_component_DataWrapper, {
            key: 11,
            class: "w-6/12",
            label: "No. Registrasi Pajak (NPWP)",
            value: _ctx.detailData.partnerTaxNumber
          }, null, 8, ["value"]))
        : (_ctx.detailData.partnerType.toLowerCase() === 'sub-console')
          ? (_openBlock(), _createBlock(_component_DataWrapper, {
              key: 12,
              class: "w-6/12",
              label: "Nama Consolidator",
              value: _ctx.detailData.partnerParentName
            }, null, 8, ["value"]))
          : _createCommentVNode("", true),
      (
          _ctx.detailData.partnerType.toLowerCase() === 'console' ||
            _ctx.detailData.partnerType.toLowerCase() === 'sub-console'
        )
        ? (_openBlock(), _createBlock(_component_DataWrapper, {
            key: 13,
            class: "w-6/12",
            label: "Periode Kontrak",
            value: 
          `${_ctx.formatDateWithoutTime(_ctx.detailData.partnerStartContract)} -
            ${_ctx.formatDateWithoutTime(_ctx.detailData.partnerEndContract)}`
        
          }, null, 8, ["value"]))
        : (_openBlock(), _createBlock(_component_DataWrapper, {
            key: 14,
            class: "w-6/12",
            label: "Tipe Partner Area",
            value: _ctx.detailData.partnerParentType
          }, null, 8, ["value"])),
      (_ctx.detailData.partnerType.toLowerCase() === 'pos')
        ? (_openBlock(), _createBlock(_component_DataWrapper, {
            key: 15,
            class: "w-6/12",
            label: "Nama Partner Area",
            value: _ctx.detailData.partnerParentName
          }, null, 8, ["value"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode("div", _hoisted_3, [
      _createVNode(_component_DataWrapper, {
        class: "w-6/12",
        label: "Latitude",
        value: _ctx.detailData.partnerLatitude
      }, null, 8, ["value"]),
      _createVNode(_component_DataWrapper, {
        class: "w-6/12",
        label: "Longitude",
        value: _ctx.detailData.partnerLongitude
      }, null, 8, ["value"])
    ]),
    (_ctx.detailData.partnerType === 'pos')
      ? (_openBlock(), _createBlock("div", _hoisted_4, [
          _createVNode(_component_DataWrapper, {
            class: "w-6/12",
            label: "Tipe POS",
            value: 
          _ctx.mappingPosType(_ctx.detailData.partnerPosType)
        
          }, null, 8, ["value"]),
          (_ctx.detailData.partnerPosType === 'branch' || _ctx.detailData.partnerPosType === 'pickup')
            ? (_openBlock(), _createBlock(_component_DataWrapper, {
                key: 0,
                class: "w-6/12",
                label: "POS Parent",
                value: _ctx.detailData.partnerPosParentName
              }, null, 8, ["value"]))
            : _createCommentVNode("", true),
          (_ctx.detailData.partnerPosType !== 'pickup')
            ? (_openBlock(), _createBlock(_component_DataWrapper, {
                key: 1,
                class: "w-9/12",
                label: "Komisi Pos Cabang",
                value: _ctx.commissionBranchValue
              }, null, 8, ["value"]))
            : _createCommentVNode("", true),
          _createVNode(_component_DataWrapper, {
            class: "w-6/12",
            label: "COD Delivery Partner",
            value: _ctx.detailData.partnerIsCodDelivery ? 'Yes' : 'No'
          }, null, 8, ["value"]),
          _createVNode(_component_DataWrapper, {
            class: "w-6/12",
            label: "COD Booking Partner",
            value: _ctx.detailData.partnerIsCodBooking ? 'Yes' : 'No'
          }, null, 8, ["value"]),
          _createVNode(_component_DataWrapper, {
            class: "w-6/12",
            label: "PCU",
            value: _ctx.detailData.partnerIsPcu ? 'Yes' : 'No'
          }, null, 8, ["value"])
        ]))
      : _createCommentVNode("", true)
  ]))
}