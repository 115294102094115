import { toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, mergeProps as _mergeProps, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "\n      bg-white\n      flex flex-col\n      text-12px\n      xxl:text-14px\n      overflow-hidden\n      h-screen\n      relative\n    " }
const _hoisted_2 = { class: "flex w-9/12 gap-x-4" }
const _hoisted_3 = {
  key: 0,
  class: "\n            font-semibold\n            text-20px\n            whitespace-no-wrap\n            text-black-lp-200\n            capitalize\n          "
}
const _hoisted_4 = { class: "overflow-auto h-screen" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Search = _resolveComponent("Search")!
  const _component_AdvancedFilter = _resolveComponent("AdvancedFilter")!
  const _component_lp_button = _resolveComponent("lp-button")!
  const _component_Header = _resolveComponent("Header")!
  const _component_TableV2 = _resolveComponent("TableV2")!
  const _component_freeze_unfreeze_popup = _resolveComponent("freeze-unfreeze-popup")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      (_ctx.showHeader)
        ? (_openBlock(), _createBlock(_component_Header, {
            key: 0,
            class: "items-center"
          }, {
            default: _withCtx(() => [
              _createVNode("div", _hoisted_2, [
                (_ctx.posId)
                  ? (_openBlock(), _createBlock("div", _hoisted_3, _toDisplayString(_ctx.$route?.meta.name), 1))
                  : _createCommentVNode("", true),
                _createVNode(_component_Search, {
                  class: "w-4/12",
                  onInput: _ctx.onSearch,
                  onClear: _ctx.clearSearch,
                  value: _ctx.searchValue,
                  placeholder: "Cari nama POS"
                }, null, 8, ["onInput", "onClear", "value"]),
                _createVNode(_component_AdvancedFilter, {
                  modelValue: _ctx.advancedFilter,
                  "onUpdate:modelValue": _ctx.onUpdateAdvancedFilter,
                  onFilter: _ctx.onRequest
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onFilter"])
              ]),
              (_ctx.isCreateAble)
                ? (_openBlock(), _createBlock(_component_lp_button, {
                    key: 0,
                    onClick: _ctx.goToCreate,
                    title: "Buat POS",
                    textColor: "white"
                  }, null, 8, ["onClick"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode("div", _hoisted_4, [
        _createVNode(_component_TableV2, {
          pagination: _ctx.pagination,
          "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => (_ctx.pagination = $event)),
          isEmpty: !_ctx.dataTable.length && _ctx.firstRequest && !_ctx.isError && !_ctx.isLoading,
          isFilter: !!_ctx.searchValue && !!_ctx.advancedFilter.statusContract &&
    !!_ctx.advancedFilter.statusPartner && !!_ctx.advancedFilter.posType,
          emptyMessage: "Belum ada data Pos yang dapat ditampilkan",
          loading: _ctx.isLoading,
          data: _ctx.dataTable,
          columns: _ctx.isActionAble ? _ctx.columns: _ctx.columns.slice(0, -1),
          class: "my-2",
          onRequest: _ctx.onRequest,
          onTryAgain: _ctx.onRequest,
          onClick: _ctx.goToDetail,
          isDetailAble: _ctx.isDetailAble,
          errorCause: _ctx.errorCause,
          totalColumnFreeze: _ctx.posId ? 0 : 1,
          isRightFreeze: _ctx.isActionAble,
          pinnedSubstractHeight: "290px",
          borderTop: false
        }, null, 8, ["pagination", "isEmpty", "isFilter", "loading", "data", "columns", "onRequest", "onTryAgain", "onClick", "isDetailAble", "errorCause", "totalColumnFreeze", "isRightFreeze"])
      ])
    ]),
    (_ctx.freezeUnFreezeModal)
      ? (_openBlock(), _createBlock(_component_freeze_unfreeze_popup, _mergeProps({
          key: 0,
          onFinish: _ctx.onRequest,
          modelValue: _ctx.freezeUnFreezeModal,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.freezeUnFreezeModal = $event))
        }, _ctx.partnerSelected), null, 16, ["onFinish", "modelValue"]))
      : _createCommentVNode("", true)
  ], 64))
}