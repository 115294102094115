
import { Vue, Options, prop } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";

class Props {
  detailData = prop<any>({
    type: Object,
    required: true
  });
}

@Options({
  components: {
    Title
  },
  props: {
    detailData: {
      type: Object,
      required: true
    }
  }
})
export default class DetailContactInformation extends Vue.with(Props) {
  get contacts() {
    return this.detailData.partnerContactPhoneNumber
      .map((key: any) => key.no)
      .toString();
  }
}
