
import { Vue, Options, prop } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";

class Props {
  detailData = prop<any>({
    type: Object,
    required: true
  });
}

@Options({
  components: {
    Title
  }
})
export default class DetailPartnerInformation extends Vue.with(Props) {
  get additionalCities() {
    if (this.detailData.partnerCity.length > 0) {
      return this.detailData.partnerCity
        .map((item: any) => item.name)
        .join(", ");
    }
    return "-";
  }
  mappingPosType(type: string) {
    const mapping: any = {
      "branch": "POS Child",
      "pickup": "POS Dipickup",
      "parent": "POS Parent"
    };
    return mapping[type];
  }
  get commissionBranchValue() {
    return this.detailData.partnerPosBranchCommission ? `${this.detailData.partnerPosBranchCommission}%` : "-"
  }
}
