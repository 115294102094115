
import { Options, Vue } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import CreateUpdate from "@/app/ui/components/data-wrapper/create-update.vue";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import OverlayPanel from "primevue/overlaypanel";
import Book from "@/app/ui/views/shipment/booking/create-booking/payment-detail/book.vue";
import { checkRolePermission, formatDate, formatPrice } from "@/app/infrastructures/misc/Utils";
import { PartnerController } from "@/app/ui/controllers/PartnerController";
import DetailPartnerInformation from "./detail-partner-information.vue";
import DetailContactInformation from "./detail-contact-information.vue";
import DetailBankInformation from "./detail-bank-information.vue";
import DetailContractInformation from "./detail-contract-information.vue";
import DetailAdditionalInformation from "./detail-additional-information.vue";
import DetailRefferalPosInformation from "./detail-refferal-code.vue";
import FreezeUnfreezePopup from "@/app/ui/views/sales/partner-management/freeze-unfreeze-popup.vue";
import QRCodeProfile from "@/app/ui/components/qr-code-hub/index.vue";
import PosBranch from "../pos/index.vue";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { PARTNER_MANAGEMENT } from "@/app/infrastructures/misc/RolePermission";
import levelingPartner from "@/app/infrastructures/misc/common-library/LevelingPartner"

@Options({
  components: {
    DetailLayout,
    CreateUpdate,
    Title,
    OverlayPanel,
    Book,
    DetailPartnerInformation,
    DetailContactInformation,
    DetailBankInformation,
    FreezeUnfreezePopup,
    QRCodeProfile,
    DetailRefferalPosInformation,
    PosBranch,
    DetailContractInformation,
    DetailAdditionalInformation
  },
  computed: {
    isEditAble() {
      return checkRolePermission(PARTNER_MANAGEMENT.EDIT);
    }
  }
})
export default class DetailPartner extends Vue {
  tabActive = "detail-partner-pos-parent";
  tabs = [
    { value: "detail-partner-pos-parent", title: "Detail POS Induk" },
    { value: "detail-partner-pos-branch", title: "Daftar POS Cabang & Di Pickup" }
  ];

  get isPosParent() {
    return this.tabActive === "detail-partner-pos-parent";
  }

  created() {
    this.getDetail();
  }

  get getTitle() {
    return `${this.detailData.partnerName}`;
  }

  async getDetail() {
    const isPos = !!(this.$route.name === "detail-partner-pos");
    await PartnerController.getPartnerDetail({
      id: this.id,
      tieringLevel: isPos
    });
  }

  get detailData() {
    return PartnerController.partnerDetail;
  }

  get id(): number {
    return Number(this.$route.params.id);
  }

  // route navigation
  goBack() {
    const path = this.posId
      ? "/pos-cabang"
      : `/partner/${this.partnerTypeUser}`;
    this.$router.push(`${path}`);
  }

  get convertCreatedAt() {
    return formatDate(this.detailData.partnerCreatedAt);
  }

  get convertUpdatedAt() {
    return formatDate(this.detailData.partnerUpdatedAt);
  }

  get convertPartnerBalance() {
    return formatPrice(this.detailData.partnerBalance);
  }

  get isLoading() {
    return PartnerController.isLoading;
  }
  get isError() {
    return PartnerController.isError;
  }
  get errorCause() {
    return PartnerController.errorCause;
  }
  get partnerTypeUser() {
    if (this.detailData.partnerType === "console") {
      return "consolidator";
    } else if (this.detailData.partnerType === "sub-console") {
      return "sub-consolidator";
    } else {
      return "pos";
    }
  }

  get isPos() {
    return this.partnerTypeUser === "pos";
  }

  goToEdit(id: any) {
    this.$router.push(`/partner/${this.partnerTypeUser}/${id}/edit`);
  }

  freezeUnFreezeModal = false;
  partnerSelected: any = "";
  handleFreezeUnFreeze() {
    this.partnerSelected = {
      id: this.detailData.partnerId,
      name: this.detailData.partnerName,
      status: this.detailData.partnerIsBanned,
      type: this.detailData.partnerType
    };
    this.freezeUnFreezeModal = true;
  }

  downloadQRCode() {
    const qrcodeProfile: any = this.$refs.qrcodeProfile;
    qrcodeProfile.download();
  }

  get posId() {
    return AccountController.accountData.account_type_detail.id;
  }

  get level() {
    return levelingPartner(this.detailData.partnerPosTieringLevel);
  }
}
