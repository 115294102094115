
import formatDate from "@/app/infrastructures/misc/common-library/FormatDate";
import { LocationController } from "@/app/ui/controllers/LocationController";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import router from "@/app/ui/router";
import { Hub } from "@/domain/entities/Hub";
import { ModalMessageEntities } from "@/domain/entities/MainApp";
import debounce from "lodash/debounce";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  computed: {
    getTitle() {
      return this.$route.meta.name;
    },
  },
  beforeRouteLeave(to: any, _: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (!this.openSuccess) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      this.openSuccess = false;
      next();
    }
  },
})
export default class FormHub extends Vue {
  form = new Hub();

  // error
  error = {
    phone: false,
    contactPhone: false,
    contactEmail: false,
    hubName: false,
  };
  newPath = "";
  answer = false;
  openSuccess = false;
  isEditTerminated = false;
  pageType = "create";
  isLoading = false;
  errCause = "";
  isError = false;

  onOpenClosePage(to: any) {
    this.isEditTerminated = true;
    this.newPath = to.path;
  }

  handlerClose() {
    this.isEditTerminated = false;
    this.answer = true;
    router.push(this.newPath);
  }

  handlerCancelClose() {
    this.answer = false;
    this.isEditTerminated = false;
  }

  get errorInput() {
    let result = false;
    Object.entries(this.error).forEach(([, value]) => {
      if (value) {
        result = value;
      }
    });
    return result;
  }

  get isMandatory() {
    return !(
      this.form.hubName &&
      this.form.hubDistrict &&
      this.form.originCity &&
      this.form.hubAddress &&
      this.form.hubPhoneNumber &&
      this.form.hubContactPersonName &&
      this.form.hubContactPersonPhoneNumber &&
      this.form.hubContactPersonEmail
    );
  }

  get isEditPage() {
    return this.pageType === "update";
  }

  mounted() {
    this.fetchCityList("");
    this.fetchDistricts("");
    this.validatePage(this.$route);
  }

  mappingDetailHub(id: number) {
    this.form = new Hub({
      hubId: +id,
      hubName: "Hub 1",
      hubCode: "HP1",
      originCity: "Jakarta",
      consolidator: "PT Lion Express",
      hubAddress:
        "Jl. Kedoya Agave Raya No.55, Kedoya Sel., Kec. Kb. Jeruk, Kota Jakarta Barat",
      hubDistrict: "Kebon Jeruk, Jakarta Barat",
      hubPhoneNumber: "81234567890",
      hubContactPersonName: "John Dhoe",
      hubContactPersonPhoneNumber: "81234567890",
      hubContactPersonEmail: "johndhoe@mail.com",
      status: "Aktif",
      isActive: true,
      hubCreatedAt: "2021-08-14T05:08:18+07:00",
      hubCreatedBy: "Console CGK",
      hubUpdatedAt: "2021-08-14T05:08:18+07:00",
      hubUpdatedBy: "Console CGK",
    });
  }

  validatePage(route: any) {
    if (route.params.id) {
      this.pageType = "update";
      this.mappingDetailHub(route.params.id);
    }
  }

  formatDate(date: string) {
    return formatDate(date);
  }

  //city
  get cityListData() {
    return LocationController.cityData.cityData.map((e) => ({
      code: e.code,
      name: `${e.name}`,
    }));
  }

  fetchCityList = debounce((search: string) => {
    if (search.length > 2 || !search) {
      LocationController.getCityList({
        search: search,
        status: "",
        page: 1,
        limit: 10,
      });
    }
  }, 500);

  get isLoadingCity() {
    return LocationController.isLoading;
  }

  // districts
  fetchDistricts = debounce((search: string) => {
    LocationController.getDistrictList({
      search: search,
      status: "",
      page: 1,
      limit: 10,
      cache: true
    });
  }, 250);

  get districts() {
    return LocationController.districtData.districtData.map((e) => ({
      id: e.id,
      name: `${e.name}, ${e.cityName}`,
      code: `${e.code}`,
      cityCode: `${e.cityCode}`,
    }));
  }

  get isLoadingDistricts() {
    return LocationController.isLoadingDistrict;
  }

  //phone
  formatNumber(value: string) {
    return value.replace(/^62|^0|[\D]+/g, "");
  }

  validatePhone(value: string) {
    this.error.phone = !/^\d+$/.test(value) || value.length < 10;
  }

  validateContactPhone(value: string) {
    this.error.contactPhone = !/^\d+$/.test(value) || value.length < 10;
  }

  // email
  validateEmail(value: string) {
    this.error.contactEmail = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
  }

  // back
  goBack() {
    router.go(-1);
  }

  //status
  onUpdateStatus() {
    this.form.isActive = !this.form.isActive;
  }

  get labelToggle() {
    return this.form.isActive ? "Aktif" : "Tidak Aktif"
  }

  showModalConfirmation() {
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        image: "are-you-sure",
        title: "Ubah Hub?",
        message: "Pastikan data yang diperbarui telah sesuai",
        textSuccess: "Simpan",
        textCancel: "Kembali",
        onClose: () => {
          MainAppController.closeMessageModal();
        },
        onSubmit: () => {
          MainAppController.closeMessageModal();
          this.onSave();
        },
      })
    );
  }

  onSave() {
    MainAppController.showLoading();
    //only testing for UI error

    setTimeout(() => {
      if (this.form.hubName === "HUB Selatan") {
        this.error.hubName = true;
        MainAppController.closeLoading();
        return;
      }
      MainAppController.closeLoading();
      MainAppController.showMessageModal(
      new ModalMessageEntities({
        image: "image-modal-success",
        title: `Hub Berhasil ${this.isEditPage ? "Disimpan" : "Dibuat"}`,
        message: this.isEditPage
          ? "Perubahan Hub telah tersimpan"
          : "Hub akan terhubung dengan consolidator yang sudah Anda pilih.",
        textSuccess: "OK",
        onSubmit: () => {
          MainAppController.closeMessageModal();
          this.$router.push(`/partner/${this.$route.meta.form}`);
        },
      })
    );
    }, 1000);
    this.openSuccess = true;
  }
}
