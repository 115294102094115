import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "space-y-6 ml-4" }
const _hoisted_2 = { class: "flex gap-y-6 flex-row flex-wrap" }
const _hoisted_3 = { class: "w-6/12 space-y-6" }
const _hoisted_4 = { class: "w-6/12 space-y-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Title, { label: "Informasi Kontak" }),
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode(_component_DataWrapper, {
          class: "w-full",
          label: "Nama",
          value: _ctx.detailData.partnerContactName
        }, null, 8, ["value"]),
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.detailData.partnerContactPhoneNumber, (phone, index) => {
          return (_openBlock(), _createBlock(_component_DataWrapper, {
            key: index,
            class: "w-full",
            label: "No. Telepon",
            value: phone.no
          }, null, 8, ["value"]))
        }), 128))
      ]),
      _createVNode("div", _hoisted_4, [
        _createVNode(_component_DataWrapper, {
          class: "w-full",
          label: "Jabatan",
          value: _ctx.detailData.partnerContactJobTitle
        }, null, 8, ["value"]),
        _createVNode(_component_DataWrapper, {
          class: "w-full",
          label: "Email",
          value: _ctx.detailData.partnerContactEmail
        }, null, 8, ["value"])
      ])
    ])
  ]))
}