/* eslint-disable @typescript-eslint/camelcase */
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule
} from "vuex-module-decorators";
import store from "@/store";
import { ConsolidatorEntities } from "@/domain/entities/Consolidator";
import { Pagination } from "@/domain/entities/Pagination";
import { container } from "tsyringe";
import { ConsolidatorPresenter } from "../presenters/ConsolidatorPresenter";

export interface ConsolidatorState {
  consolidatorData: ConsolidatorEntities;
}

@Module({ namespaced: true, dynamic: true, store, name: "consolidator" })
class ConsolidatorStore extends VuexModule implements ConsolidatorState {
  public consolidatorData = new ConsolidatorEntities(new Pagination(1, 10), []);
  public isLoading = false;
  public isError = false;
  public isErrorCause = "";
  public search = "";
  public filterConsolidator = {
    statusContract: "",
    statusPartner: "",
    originCity: {
      name: "",
      code: ""
    }
  };
  public firstRequest = true;

  @Action
  public getConsolidatorList(params: {
    page: number;
    limit: number;
    search: string;
    contractStatus: string;
    cityCode: string;
    status: string;
  }) {
    this.setLoading(true);
    const presenter = container.resolve(ConsolidatorPresenter);
    return presenter
      .getConsolidatorList(
        params.page,
        params.limit,
        params.search,
        params.contractStatus,
        params.cityCode,
        params.status
      )
      .then((res: ConsolidatorEntities) => {
        this.setConsolidatorData(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((err: any) => {
        this.setError(true);
        this.setErrorCause(err.response ? "server" : "internet");
        this.setConsolidatorData(
          new ConsolidatorEntities(new Pagination(0, 0), [])
        );
      })
      .finally(() => this.setLoading(false));
  }

  @Action
  public async initDataTable() {
    this.setFirstPage();
    this.setFirstRequest(true);
    this.setSearch("");
    await this.getConsolidatorList({
      page: this.consolidatorData.pagination.page,
      limit: this.consolidatorData.pagination.limit,
      search: "",
      contractStatus: "",
      cityCode: "",
      status: ""
    });
  }

  @Action
  public async fetchDataTable() {
    this.setFirstRequest(false);
    await this.getConsolidatorList({
      page: this.consolidatorData.pagination.page,
      limit: this.consolidatorData.pagination.limit,
      search: this.search,
      contractStatus: this.filterConsolidator.statusContract,
      cityCode: this.filterConsolidator.originCity.code || "",
      status: this.filterConsolidator.statusPartner
    });
  }

  @Action
  public async searchAction(value: string) {
    this.setSearch(value);
    this.setFirstPage();
    await this.fetchDataTable();
  }

  @Action
  public setFirstPage() {
    this.consolidatorData.pagination.page = 1;
  }

  @Action
  public async clear() {
    await this.searchAction("");
  }

  @Mutation
  private setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @Mutation
  private setSearch(value: string) {
    this.search = value;
  }

  @Mutation
  private setError(boolean: boolean) {
    this.isError = boolean;
  }

  @Mutation
  private setConsolidatorData(value: ConsolidatorEntities) {
    this.consolidatorData = value;
  }

  @Mutation
  public setErrorCause(str: string) {
    this.isErrorCause = str;
  }

  @Mutation
  public setFirstRequest(value: boolean) {
    this.firstRequest = value;
  }

  @Mutation
  public setFilterConsolidator(value: any) {
    this.filterConsolidator = value;
  }
}

export const ConsolidatorController = getModule(ConsolidatorStore);
