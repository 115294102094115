import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "space-y-6 ml-4" }
const _hoisted_2 = { class: "flex gap-y-6 flex-row flex-wrap" }
const _hoisted_3 = { class: "w-6/12 space-y-6" }
const _hoisted_4 = { class: "w-6/12 space-y-6" }
const _hoisted_5 = { class: "flex gap-y-6 flex-row flex-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!
  const _component_ImagePreview = _resolveComponent("ImagePreview")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_Title, { label: "Periode Kontrak" }),
      _createVNode("div", _hoisted_2, [
        _createVNode("div", _hoisted_3, [
          _createVNode(_component_DataWrapper, {
            class: "w-full",
            label: "Dimulai Dari",
            value: _ctx.formatDateWithoutTime(_ctx.detailData.partnerStartContract)
          }, null, 8, ["value"])
        ]),
        _createVNode("div", _hoisted_4, [
          _createVNode(_component_DataWrapper, {
            class: "w-full",
            label: "Sampai",
            value: _ctx.formatDateWithoutTime(_ctx.detailData.partnerEndContract)
          }, null, 8, ["value"])
        ])
      ]),
      _createVNode("div", _hoisted_5, [
        _createVNode(_component_DataWrapper, {
          label: "Berkas Pendukung",
          class: "w-full"
        }, {
          default: _withCtx(() => [
            (_ctx.filesView.length > 1)
              ? (_openBlock(), _createBlock("div", {
                  key: 0,
                  class: `${_ctx.filesView.length > 5 ? 'grid grid-cols-5' : 'flex flex-row'} gap-3 mt-2`
                }, [
                  (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.filesView, (photo) => {
                    return (_openBlock(), _createBlock("div", { key: photo }, [
                      (photo.type.toLowerCase() === 'pdf')
                        ? (_openBlock(), _createBlock("img", {
                            key: 0,
                            src: require(`@/app/ui/assets/images/default-pdf.jpeg`),
                            alt: "file",
                            class: "rounded object-cover cursor-pointer",
                            style: {"height":"104px","width":"104px"},
                            onClick: _cache[1] || (_cache[1] = () => _ctx.onPreview(require(`@/app/ui/assets/images/default-pdf.jpeg`)))
                          }, null, 8, ["src"]))
                        : (_openBlock(), _createBlock("img", {
                            key: 1,
                            src: photo.url,
                            alt: "file",
                            class: "rounded object-cover cursor-pointer",
                            style: {"height":"104px","width":"104px"},
                            onClick: () => _ctx.onPreview(photo.url)
                          }, null, 8, ["src", "onClick"]))
                    ]))
                  }), 128))
                ], 2))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ])
    ]),
    _createVNode(_component_ImagePreview, {
      modelValue: _ctx.photoPreview,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.photoPreview = $event)),
      data: _ctx.photoPreviews,
      image: _ctx.photo,
      isTerm: false
    }, null, 8, ["modelValue", "data", "image"])
  ], 64))
}