
import { Options, Vue } from "vue-class-component";
import { flags } from "@/feature-flags";

@Options({
  components: {}
})
export default class Client extends Vue {
  // Tabs
  tabs = [
    { name: "consolidator", title: "Consolidator", isShow: true },
    { name: "sub-consolidator", title: "Sub Consolidator", isShow: true },
    { name: "pos", title: "POS", isShow: true },
    {
      name: "hub",
      title: "Hub",
      isShow: flags.feature_show_hide_hub_list.isEnabled()
    }
  ];

  get filteredTabs() {
    return this.tabs.filter((tab: any) => {
      return tab.isShow;
    });
  }

  changeTabs(value: string) {
    this.$router.push({
      name: value
    });
  }

  isTabActive(name: string) {
    return name === this.$route.name;
  }
}
