
import { Options, Vue, prop } from "vue-class-component";
import { PartnerController } from "@/app/ui/controllers/PartnerController";

class Props {
  modelValue = prop<boolean>({
    default: false
  });
  name = prop<string>({
    default: ""
  });
  id = prop<number>({
    default: 0
  });
  status = prop<boolean>({
    default: false
  });
  type = prop<string>({
    default: ""
  });
}

@Options({
  emits: ["finish", "update:modelValue"],
  components: {}
})
export default class FreezeUnfreezePopup extends Vue.with(Props) {
  mounted() {
    if (this.typeModal === "unfreeze") {
      this.onSubmitReasonModal();
    }
  }

  // partner data
  get partnerName() {
    return this.name;
  }
  get partnerId() {
    return this.id;
  }
  get partnerType() {
    return this.type;
  }
  get typeModal() {
    return this.status ? "unfreeze" : "freeze";
  }

  // Reason Popup Modal
  reasonModal = true;
  partnerFreezeReason = "";
  onCloseReasonModal() {
    this.reasonModal = true;
    this.confirmationModal = false;
    this.finishModal = false;
    this.partnerFreezeReason = "";
    this.$emit("update:modelValue", false);
  }
  onSubmitReasonModal() {
    this.reasonModal = false;
    this.confirmationModal = true;
  }
  get isDisabled() {
    return this.partnerFreezeReason === "";
  }

  // Confirmation Modal
  confirmationModal = false;
  onCloseConfirmationModal() {
    if (this.typeModal === "unfreeze") {
      this.onCloseReasonModal();
    } else {
      this.confirmationModal = false;
      this.reasonModal = true;
    }
  }
  async onSubmitConfirmationModal() {
    this.confirmationModal = false;
    // to do hit api freeze or unfreeze
    const resp = await PartnerController.freezeUnFreezePartner({
      id: this.partnerId,
      isBanned: !this.status,
      bannedReason: this.partnerFreezeReason,
      type: this.partnerType
    });
    this.finishModal = resp;
    this.isError = !resp;
  }
  get titleConfirmationModal() {
    if (this.typeModal === "freeze") {
      return "Konfirmasi Pembekuan";
    }
    return "Konfirmasi Pengaktifan";
  }
  get messageConfirmationModal() {
    if (this.typeModal === "freeze") {
      return `Anda yakin ingin melakukan pembekuan untuk partner “${this.partnerName}” ? `;
    }
    return `Anda yakin ingin melakukan pengaktifan atas pembekuan partner “${this.partnerName}” ? `;
  }
  get buttonConfirmationModal() {
    if (this.typeModal === "freeze") {
      return "Freeze";
    }
    return "Unfreeze";
  }

  // Finish Modal
  finishModal = false;
  onCloseFinishModal() {
    this.$emit("finish");
    this.onCloseReasonModal();
  }
  get titleFinishModal() {
    if (this.typeModal === "freeze") {
      return "Pembekuan Berhasil !";
    }
    return "Pengaktifan Berhasil !";
  }
  get messageFinishModal() {
    if (this.typeModal === "freeze") {
      return `Partner “${this.partnerName}” berhasil dibekukan.`;
    }
    return `Partner “${this.partnerName}” berhasil diaktifkan kembali.`;
  }

  // error
  isError = false;
  get errorTitle() {
    if (this.typeModal === "freeze") {
      return "Pembekuan Gagal !";
    }
    return "Pengaktifan Gagal !";
  }
}
