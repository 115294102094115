
import { Options, Vue } from "vue-class-component";
import DetailPartner from "./detail/index.vue";

@Options({
  components: {
    DetailPartner
  }
})
export default class Detail extends Vue {
  get id(): number {
    return Number(this.$route.params.id);
  }
}
