
import { Options, Vue, prop } from "vue-class-component";
import { LocationController } from "@/app/ui/controllers/LocationController";
import debounce from "lodash/debounce";
import { ConsolidatorController } from "@/app/ui/controllers/ConsolidatorController";

class Prop {
  modelValue = prop<any>({
    type: Object,
    required: true
  });
  isDisabled = prop<boolean>({
    type: Boolean,
    default: false
  });
  isHubList = prop<boolean>({
    type: Boolean,
    default: false
  });
}

@Options({
  emits: ["reset", "update:modelValue", "filter"],
  components: {},
  directives: {
    "click-outside": {
      beforeMount: function(el, binding) {
        const ourClickEventHandler = (event: any) => {
          if (!el.contains(event.target) && el !== event.target) {
            binding.value(event);
          }
        };
        el.__vueClickEventHandler__ = ourClickEventHandler;
        document.addEventListener("click", ourClickEventHandler);
      },
      unmounted: function(el) {
        document.removeEventListener("click", el.__vueClickEventHandler__);
      }
    }
  }
})
export default class AdvancedFilter extends Vue.with(Prop) {
  isChevron = false;
  openFilter = false;

  resetFilter() {
    this.filterData = {
      statusContract: "",
      statusPartner: "",
      posType: "",
      originCity: "",
      status: "",
      consolidator: ""
    };
    this.onChange();
  }

  filterData = {
    statusContract: "",
    statusPartner: "",
    posType: "",
    originCity: "",
    status: "",
    consolidator: ""
  };

  get routerName() {
    return this.$route.name;
  }

  // status contract
  openStatusContract = false;
  statusContract = [
    { name: "Pilih status", value: "" },
    { name: "Active", value: "active" },
    { name: "Waiting", value: "waiting" },
    { name: "Nearly Expired", value: "nearly-expired" },
    { name: "Expired", value: "expired" }
  ];

  // status partner
  openStatusPartner = false;
  statusPartner = [
    { name: "Pilih status", value: "" },
    { name: "Freeze", value: "banned" },
    { name: "-", value: "not-banned" }
  ];

  // status pos
  openTypePos = false;
  posType = [
    { name: "Pilih tipe", value: "" },
    { name: "POS Parent", value: "parent" },
    { name: "POS Child", value: "branch" },
    { name: "POS Dipickup", value: "pickup" }
  ];

  // status contract
  openStatus = false;
  status = [
    { name: "Pilih status", value: "" },
    { name: "Aktif", value: "active" },
    { name: "Tidak Aktif", value: "inactive" }
  ];

  get sumFilter() {
    return (
      (this.filterData.statusContract ? 1 : 0) +
      (this.filterData.statusPartner ? 1 : 0) +
      (this.filterData.posType ? 1 : 0) +
      (this.filterData.originCity ? 1 : 0) +
      (this.filterData.status ? 1 : 0) +
      (this.filterData.consolidator ? 1 : 0)
    );
  }

  onChange() {
    this.$emit("update:modelValue", this.filterData);
    this.$emit("filter");
  }

  // Origin destination
  fetchOriginDestination = debounce((search: string, type: string) => {
    const length: number = type === "code" ? 2 : 3;
    search = search.split("-")[0];
    if (!search.length) {
      this.filterData.originCity = "";
      LocationController.getCityList({
        search: "",
        status: "",
        page: 1,
        limit: 10
      });
    } else if (search.length > length) {
      LocationController.getCityList({
        search: search,
        status: "",
        page: 1,
        limit: 10
      });
    }
  }, 250);
  get loadingOriginDestination() {
    return LocationController.isLoading;
  }
  get originList() {
    return LocationController.cityData.cityData.map(city => ({
      name: city.name,
      code: city.code
    }));
  }
  get originListHub() {
    return LocationController.cityData.cityData.map(city => ({
      name: `${city.code} - ${city.name}`,
      code: city.code
    }));
  }

  // Consolidator
  fetchListConsolidator = debounce((search: string, type: string) => {
    const length: number = type === "code" ? 2 : 3;
    search = search.split("-")[0];
    if (!search.length) {
      this.filterData.consolidator = "";
      ConsolidatorController.getConsolidatorList({
        page: 1,
        limit: 10,
        search: "",
        status: "",
        cityCode: "",
        contractStatus: ""
      });
    } else if (search.length > length) {
      ConsolidatorController.getConsolidatorList({
        page: 1,
        limit: 10,
        search: search,
        status: "",
        cityCode: "",
        contractStatus: ""
      });
    }
  }, 250);
  get loadingConsolidator() {
    return ConsolidatorController.isLoading;
  }
  get consolidatorList() {
    return ConsolidatorController.consolidatorData.data.map(item => ({
      name: item.name,
      code: item.code,
      id: item.id
    }));
  }
}
