
import { Vue, Options, prop } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";

class Props {
  detailData = prop<any>({
    type: Object,
    required: true
  });
}

@Options({
  components: {
    Title
  }
})
export default class DetailRefferalCode extends Vue.with(Props) {}
