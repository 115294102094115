
import { Options, Vue } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import debounce from "lodash/debounce";
import {
  dateToDateString,
  dateToTime,
  formattedNPWP
} from "@/app/infrastructures/misc/Utils";
import Tooltip from "@/app/ui/components/tooltip/index.vue";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import { PartnerController } from "@/app/ui/controllers/PartnerController";
import { LocationController } from "@/app/ui/controllers/LocationController";
import { BeneficiaryAccountController } from "@/app/ui/controllers/BeneficiaryAccountController";
import ModalAddBank from "./modal-add-bank.vue";
import { PosController } from "@/app/ui/controllers/PosController";
import { RequestListPartner } from "@/data/payload/api/PartnerApiRequest";
import { PosData } from "@/domain/entities/Pos";
import formatInputFloat from "@/app/infrastructures/misc/common-library/FormatInputFloat";
import FileAttachment from "./file-attachment.vue";
import formatInputMoney from "@/app/infrastructures/misc/common-library/FormatInputMoney";

@Options({
  components: {
    Title,
    Tooltip,
    DetailLayout,
    ModalAddBank,
    FileAttachment
  },
  computed: {
    getTitle() {
      return this.$route.meta.name;
    }
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (!this.openSuccess) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      this.setModalSuccess(false);
      next();
    }
  }
})
export default class Update extends Vue {
  props: any = this.$props;

  // add bank midtrans enhancement
  get showAddBank() {
    return PartnerController.showAddBank;
  }
  onShowAddBank(val: boolean) {
    PartnerController.setShowAddBank(val);
  }

  onDeleteBank() {
    this.beneficiaryDetail.bankAccountLogo = "";
    this.beneficiaryDetail.bankAccountName = "";
    this.beneficiaryDetail.bankAccountLabel = "";
    this.beneficiaryDetail.bankName = "";
    this.beneficiaryDetail.bankAccountNumber = "";
    this.beneficiaryDetail.bankAccountEmail = "";
  }

  // Get Data
  async mounted() {
    await this.getDetail();
    this.filterConsolidators("");
    this.fetchDistricts("");
    await this.fecthCityListData();
    this.getBeneficiaryAccount();
    if (this.detailData.partnerPosType === 'branch' ||this.detailData.partnerPosType === 'pickup'){
      this.filterParentPos(this.detailData.partnerPosParentName);
    }
    if (this.$route.meta.form !== "pos") {
      this.filterPartnerPosReverseJourney(
        this.detailData.partnerPosReverseJourneyName
      );
    }
  }

  async getDetail() {
    await PartnerController.getPartnerDetail({
      id: Number(this.$route.params.id),
      tieringLevel: this.$route.name === "edit-partner-pos"
    });
  }

  get partnerTypeForm() {
    const type = this.$route.meta.form as string;
    const accountType: any = {
      "consolidator": "console",
      "sub-consolidator": "sub-console"
    }

    return accountType[type] ?? "pos";
  }

  getBeneficiaryAccount() {
    BeneficiaryAccountController.getBeneficiaryAccList({
      page: 1,
      limit: 1,
      bankAccType: "beneficiary",
      actortype: this.partnerTypeForm,
      actorId: Number(this.$route.params.id)
    });
  }

  get beneficiaryDetail() {
    return BeneficiaryAccountController.beneficiaryDetail;
  }

  get styleStatus() {
    switch (this.detailData.partnerContractStatus.toLowerCase()) {
      case "expired":
        return "bg-red-lp-600 text-red-lp-500 font-medium px-5";
      case "nearly-expired":
        return "font-medium bg-yellow-lp-100 text-yellow-lp-200 font-medium px-5";
      case "waiting":
        return "text-yellow-lp-100 font-medium bg-yellow-lp-200 font-medium px-5";
      default: {
        return "font-medium bg-green-lp-300 text-green-lp-200 font-medium px-5";
      }
    }
  }

  // back
  get partnerTypeUser() {
    if (this.detailData.partnerType === "console") {
      return "consolidator";
    } else if (this.detailData.partnerType === "sub-console") {
      return "sub-consolidator";
    } else {
      return "pos";
    }
  }

  get partnerNameParams() {
    return this.detailData.partnerName;
  }

  goBack() {
    this.$router.push(
      `/partner/${this.partnerTypeUser}/detail/${this.detailData.partnerId}?params=${this.partnerNameParams}`
    );
  }

  get errorCause() {
    return PartnerController.errorCause;
  }

  get detailData(): any {
    return PartnerController.partnerDetail;
  }

  get isLoading() {
    return PartnerController.isLoading;
  }

  // Format Date
  formatDate(date: string) {
    return `${dateToDateString(date)}, ${dateToTime(date)} WIB`;
  }

  isEditTerminated = false;
  newPath = "";
  answer = false;

  onOpenClosePage(to: any) {
    this.isEditTerminated = true;
    this.newPath = to.path;
  }

  removePhoneNumber(index: number) {
    this.tempIndexToRemove = index;
    if (this.tempIndexToRemove > -1) {
      this.detailData.partnerContactPhoneNumber.splice(
        this.tempIndexToRemove,
        1
      );
      this.tempIndexToRemove = -1;
    }
  }

  handlerClose() {
    this.isEditTerminated = false;
    this.answer = true;
    this.$router.push(this.newPath);
  }

  handlerCancelClose() {
    this.answer = false;
    this.isEditTerminated = false;
  }

  // saving
  openConfirmation = false;
  updateConfirmation() {
    this.openConfirmation = true;
  }

  openSuccess = false;
  async onCreate() {
    this.openConfirmation = false;
    const resp = await PartnerController.updatePartner();
    this.openSuccess = resp;
  }

  onCloseConfirmation() {
    this.openConfirmation = false;
  }

  onCloseSuccess() {
    this.goBack();
  }

  setModalSuccess(value: boolean) {
    this.openSuccess = value;
  }

  //partner name
  get partnerName() {
    if (this.detailData.partnerType === "console") {
      return "Consolidator";
    } else if (this.detailData.partnerType === "sub-console") {
      return "Sub Consolidator";
    } else {
      return "POS";
    }
  }

  // tooltip
  isOpenTooltipAddCity = false;

  // error
  error = {
    phone: false,
    taxNumber: false,
    contactPhone: false,
    contactEmail: false,
    bankEmail: false,
    accountBankNo: false,
    commision: false
  };
  // form
  form = {
    consolidators: "",
    cityExclude: [],
    phone: "",
    taxNumber: "",
    contactPhones: [
      {
        no: "",
        error: false
      }
    ]
  };
  tempIndexToRemove = 0;

  // districts
  fetchDistricts = debounce((search: string) => {
    LocationController.getDistrictList({
      search: search,
      status: "",
      page: 1,
      limit: 10,
      cache: true
    });
  }, 250);

  get districts() {
    return LocationController.districtData.districtData.map(e => ({
      id: e.id,
      name: `${e.name}, ${e.cityName}`,
      code: `${e.code}`,
      cityCode: `${e.cityCode}`
    }));
  }

  get isLoadingDistricts() {
    return LocationController.isLoadingDistrict;
  }

  // districts toppings
  get cityOver() {
    if (this.form.cityExclude.length > 5) {
      this.form.cityExclude.pop();
      return true;
    }

    return false;
  }

  get isLoadingCity() {
    return LocationController.isLoading;
  }

  async fecthCityListData() {
    LocationController.getCityList({
      search: "",
      status: "",
      page: 1,
      limit: 10
    });
  }

  get cityListData() {
    return LocationController.cityData.cityData.map(e => {
      return {
        code: e.code,
        name: e.name
      };
    });
  }

  filterCityList = debounce((search: string) => {
    if (search.length > 2 || !search) {
      LocationController.getCityList({
        search: search,
        status: "",
        page: 1,
        limit: 10
      });
    }
  }, 250);

  // consolidators
  filterConsolidators = debounce((search: string) => {
    if (this.detailData.partnerParentType !== undefined) {
      PartnerController.getConsolidatorsByCity({
        search: search,
        page: 1,
        limit: 10,
        cityCode: this.detailData.partnerLocationCityCode,
        type: this.detailData.partnerParentType,
        status: this.partnerStatus
      });
    }
  }, 250);

  get consolidators() {
    if (
      this.$route.meta.form === "sub-consolidator" ||
      this.$route.meta.form === "pos"
    ) {
      return PartnerController.consolidatorsByCity.consolidatorByCityData.map(
        e => ({
          id: e.id,
          name: `${e.name}`
        })
      );
    }
    return null;
  }

  get modelPartnerParent() {
    if (this.detailData.partnerParentId) {
      return {
        id: this.detailData.partnerParentId,
        name: this.detailData.partnerParentName
      };
    }
    return "";
  }

  onChangePartnerParent(item: any) {
    PartnerController.setPartnerParent(item);
  }

  get isLoadingConsolidators() {
    return PartnerController.loadingConsolidatorsByCity;
  }

  cityCode = "";
  partnerStatus = "";
  getConsolidatorsByCity(obj: any) {
    this.onChangePartnerParent("");
    this.form.consolidators = "";
    this.detailData.partnerLocationCityCode =
      typeof obj === "object"
        ? obj.cityCode
        : this.detailData.partnerLocationCityCode;
    PartnerController.getConsolidatorsByCity({
      search: "",
      page: 1,
      limit: 10,
      cityCode: this.detailData.partnerLocationCityCode,
      type: this.detailData.partnerParentType,
      status: this.partnerStatus
    });
    PosController.setDefaultPosList();
    this.detailData.partnerPosParentId = undefined as number | undefined;
    this.detailData.partnerPosParentName = "";
    this.detailData.partnerPosReverseJourney = "";
    if (this.$route.meta.form !== "pos") {
      this.filterPartnerPosReverseJourney("");
    }
  }

  // partners area
  partnerAreaTypeList = [
    {
      name: "Pilih partner area",
      value: ""
    },
    {
      name: "Consolidator",
      value: "console"
    },
    {
      name: "Sub Consolidator",
      value: "sub-console"
    }
  ];

  isOpenPartnerType = false;
  onOpenSelectPartnerType() {
    this.isOpenPartnerType = true;
  }

  onCloseSelectPartnerType() {
    this.isOpenPartnerType = false;
  }

  onSelectPartnerType(key: string, value: string) {
    this.onCloseSelectPartnerType();
    PartnerController.setPartnerParentType(value);
    this.onChangePartnerParent("");
    PartnerController.getConsolidatorsByCity({
      search: "",
      page: 1,
      limit: 10,
      cityCode: this.detailData.partnerLocationCityCode,
      type: this.detailData.partnerParentType,
      status: this.partnerStatus
    });
  }

  // phone
  formatNumber(value: string) {
    return value.replace(/[\D]+/g, "");
  }

  validatePhone(value: string) {
    this.error.phone = !/^\d+$/.test(value);
  }

  errorMinimalPhone() {
    this.error.phone = this.detailData.partnerPhoneNumber.length < 4;
  }

  errorContactPhone = false;
  validateContactPhone(index: number) {
    this.detailData.partnerContactPhoneNumber[index].error =
      this.detailData.partnerContactPhoneNumber[index].no.length !== 0 &&
      this.detailData.partnerContactPhoneNumber[index].no === false;
    this.errorContactPhone = this.detailData.partnerContactPhoneNumber[
      index
    ].error;
  }

  emptyContactPhone(idx: number) {
    let result = false;
    const check = this.detailData.partnerContactPhoneNumber
      ? this.detailData.partnerContactPhoneNumber
      : [];
    if (check.length !== 0) {
      this.detailData.partnerContactPhoneNumber.map(
        (key: any, index: number) => {
          if (idx === index) {
            if (key.no === "" || key.no.length < 4) {
              result = true;
              this.errorContactPhone = true;
            }
          }
        }
      );
    }
    return result;
  }

  addPhone() {
    if (this.detailData.partnerContactPhoneNumber.length > 2) {
      return;
    }

    this.detailData.partnerContactPhoneNumber.push({
      no: "",
      error: false
    });
  }

  get isMaxContactPhone() {
    return this.detailData.partnerContactPhoneNumber?.length < 3;
  }

  // npwp
  formatTax(value: string) {
    return formattedNPWP(value.replace(/[\D]+/g, ""));
  }

  validateTaxNumber(value: string) {
    this.error.taxNumber = !/[0-9-.]+/.test(value);
    if (value.length < 20) {
      this.error.taxNumber = true;
    }
  }

  formatLatLong(val: string) {
    return val.replace(/[^0-9+-.]+/g, "").replace(",", "");
  }

  // bank
  get bankModelValue() {
    if (this.beneficiaryDetail.bankName) {
      return {
        name: this.beneficiaryDetail.bankAccountLabel,
        value: this.beneficiaryDetail.bankName
      };
    }
    return "";
  }
  fetchListBank() {
    PartnerController.getListPartnerBank();
  }
  get bankList() {
    return PartnerController.partnerBank.map(bank => {
      return { name: bank.bankName, value: bank.bankCode };
    });
  }
  get loadingBank() {
    return PartnerController.isLoadingBank;
  }
  onSelectBank(model: any) {
    this.beneficiaryDetail.bankName = model.value;
    this.beneficiaryDetail.bankAccountLabel = model.name;
  }

  // email
  validateEmail(value: string) {
    this.error.contactEmail = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
  }

  validateEmailBank(value: string) {
    this.error.bankEmail =
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) && value.length !== 0;
  }

  // account bank no
  validateAccountNo(value: string) {
    this.error.accountBankNo = !/^\d+$/.test(value) && value.length !== 0;
  }

  get validateForm() {
    const validateRequired = !(
      this.detailData.partnerName &&
      this.detailData.partnerAddress &&
      this.detailData.partnerContactPhoneNumber &&
      this.detailData.partnerContactName &&
      this.detailData.partnerContactJobTitle &&
      this.detailData.partnerContactEmail &&
      this.detailData.partnerLongitude &&
      this.detailData.partnerLatitude &&
      !this.isParentPos &&
      !this.isParentPosDipickup &&
      this.validateFormUploadForPos
    );
    const validateError =
      this.error.accountBankNo ||
      this.error.bankEmail ||
      this.error.contactEmail ||
      this.error.phone ||
      this.error.taxNumber ||
      this.error.contactPhone ||
      this.errorContactPhone;
    return validateRequired || validateError;
  }

  get validateFormUploadForPos() {
    if (this.detailData.partnerType === "pos") {
      return !this.validateFiles && !this.isLoadingUpload;
    }

    return true;
  }

  get validateParent() {
    if (this.detailData.partnerType === "console") {
      return false;
    }
    return !(
      this.detailData.partnerParentId && this.detailData.partnerParentName
    );
  }

  // kecamatan kota
  errorCaptionKecamatanKota = "";
  errorKecamatanKota = false;

  onCheckedCODDeliveryPartner(value: any) {
    this.detailData.partnerIsCodDelivery = value;
  }

  onCheckedPartnerIsPCU(value: any) {
    this.detailData.partnerIsPcu = value;
  }

  onCheckedCODBookingPartner(value: any) {
    this.detailData.partnerIsCodBooking = value;
  }

  // tipe pos
  openTypePos = false;
  typePos = [
    { name: "Pilih tipe", value: "" },
    { name: "POS Parent", value: "parent" },
    { name: "POS Child", value: "branch" },
    { name: "POS Dipickup", value: "pickup" }
  ];

  onChangeTypePos(name: string, val: string) {
    const partnerPos: any = {
      "": {
        id: undefined as number | undefined,
        name: ""
      },
      parent: {
        id: undefined as number | undefined,
        name: ""
      },
      branch: {
        id: this.detailData.partnerPosParentIdDefault,
        name: this.detailData.partnerPosParentNameDefault
      },
      pickup: {
        id: this.detailData.partnerPosParentIdDefault,
        name: this.detailData.partnerPosParentNameDefault
      }
    };

    this.detailData.partnerPosParentId = partnerPos[val].id;
    this.detailData.partnerPosParentName = partnerPos[val].name;
    this.detailData.partnerPosType = val;
    this.openTypePos = false;
  }

  // parent pos
  get isParentPos() {
    if (this.detailData.partnerType === "pos") {
      if (this.detailData.partnerPosType) {
        return (
          this.detailData.partnerPosType === "branch" &&
          !this.detailData.partnerPosParentId
        );
      } else {
        return true;
      }
    }

    return false;
  }

  get isParentPosDipickup() {
    if (this.detailData.partnerType === "pos") {
      if (this.detailData.partnerPosType) {
        return (
          this.detailData.partnerPosType === "pickup" &&
          !this.detailData.partnerPosParentId
        );
      } else {
        return true;
      }
    }

    return false;
  }

  filterParentPos = debounce((search: string) => {
    PosController.getPosList(
      new RequestListPartner({
        page: 1,
        limit: 10,
        type: "pos",
        cityCode: this.detailData.partnerLocationCityCode,
        search: search,
        posType: "parent"
      })
    );
  }, 250);

  get isLoadingParentPos() {
    return PosController.isLoading;
  }

  get parentPos() {
    return PosController.posList.data.map((item: PosData) => ({
      id: item.id,
      name: item.name
    }));
  }

  setParterPosParentId(obj: any) {
    this.detailData.partnerPosParentId = obj.id;
  }

  formatCommission(value: string) {
    return formatInputFloat(value, 2);
  }

  errorCaptionCommision = "";
  validateCommision(value: string) {
    value = value.replace(/,/g, ".");
    if (Number(value) < 0) {
      this.error.commision = true;
      this.errorCaptionCommision = "Komisi tidak boleh nol/kosong";
    } else if (Number(value) > 100) {
      this.error.commision = true;
      this.errorCaptionCommision = "Komisi tidak boleh lebih dari 100%";
    } else {
      this.error.commision = false;
    }
  }
  filterPartnerPosReverseJourney = debounce((search: string) => {
    PosController.getPosList(
      new RequestListPartner({
        page: 1,
        limit: 10,
        type: "pos",
        cityCode:
          typeof this.detailData.partnerDistrict === "object"
            ? this.detailData.partnerDistrict.city_code
            : this.detailData.partnerLocationCityCode,
        search: search,
        posType: "parent",
        contractStatus: "active"
      })
    );
  }, 250);

  get posReverseJourney() {
    return PosController.posList.data?.map((data: any) => {
      return {
        name: `${data.code}-${data.name}`,
        id: data.id
      };
    });
  }

  updateFiles(files: string[]) {
    this.detailData.partnerPosAttachFiles = files;
  }

  get validateFiles() {
    const newFiles = this.detailData.partnerPosAttachFiles.filter((item: string) => item);
    return newFiles.length < 3
  }

  formatDebitBalance(value: string) {
    return formatInputMoney(value.replace(/[\D]+/g, ""));
  }

  isLoadingUpload = false;
  setLoadingUpload(status: boolean) {
    this.isLoadingUpload = status;
  }
}
