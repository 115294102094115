
import { Vue, Options } from "vue-class-component";
import { PartnerSubconsoleController } from "@/app/ui/controllers/PartnerSubconsoleController";
import EmptyState from "@/app/ui/components/empty-list/index.vue";
import checkRolePermission from "@/app/infrastructures/misc/common-library/CheckRolePermission";
import ellipsisString from "@/app/infrastructures/misc/common-library/EllipsisString";
import formatDateWithoutTime from "@/app/infrastructures/misc/common-library/FormatDateWithoutTime";
import { PARTNER_MANAGEMENT } from "@/app/infrastructures/misc/RolePermission";
import FreezeUnfreezePopup from "@/app/ui/views/sales/partner-management/freeze-unfreeze-popup.vue";
import AdvancedFilter from "@/app/ui/views/sales/partner-management/advanced-filter.vue";

@Options({
  components: {
    AdvancedFilter,
    EmptyState,
    FreezeUnfreezePopup,
  },
  computed: {
    isCreateAble() {
      return checkRolePermission(PARTNER_MANAGEMENT.CREATE);
    },
    isDetailAble() {
      return checkRolePermission(PARTNER_MANAGEMENT.DETAIL);
    },
    isActionAble() {
      return checkRolePermission(PARTNER_MANAGEMENT.EDIT);
    },
  },
})
export default class PartnerSubConsolidator extends Vue {
  mounted() {
    PartnerSubconsoleController.initDataTable();
  }

  fetchPartnerSubconsoleList() {
    PartnerSubconsoleController.fetchPartnerSubconsoleList();
  }

  get advancedFilter() {
    return PartnerSubconsoleController.advancedFilter;
  }
  onUpdateAdvancedFilter(value: any) {
    PartnerSubconsoleController.setAdvancedFilter(value);
  }

  // loading
  get isLoading() {
    return PartnerSubconsoleController.isLoading;
  }

  get isFirstRequest() {
    return PartnerSubconsoleController.isFirstRequest;
  }

  // error cause
  get errorCause() {
    return PartnerSubconsoleController.errorCause;
  }

  // filter search
  get searchValue() {
    return PartnerSubconsoleController.filter.search;
  }

  onSearch(value: string) {
    PartnerSubconsoleController.searchAction(value);
  }

  clearSearch() {
    PartnerSubconsoleController.searchAction("");
  }

  // Table
  get columns() {
    return [
      {
        name: "No.",
        styleHead: "w-24 text-left whitespace-no-wrap",
        render: (item: any, index: number) => {
          return `<div class="overflow-ellipsis text-left">${index + 1}</div>`;
        },
      },
      {
        name: "Kode Sub Consolidator",
        styleHead: "w-56 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${item.partnerSubconsoleCode}</div>`;
        },
      },
      {
        name: "Nama Sub Consolidator",
        styleHead: "w-64 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${ellipsisString(
            item.partnerSubconsoleName,
            80
          )}</div>`;
        },
      },
      {
        name: "Kecamatan, Kota",
        styleHead: "w-400px text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${item.partnerSubconsoleDistrict}, ${item.partnerSubconsoleCity}</div>`;
        },
      },
      {
        name: "Nama Consolidator",
        styleHead: "w-64 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${ellipsisString(
            item.partnerConsoleName,
            80
          )}</div>`;
        },
      },
      {
        name: "Akhir Kontrak",
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="text-black-lp-300">${formatDateWithoutTime(
            item.partnerSubconsoleEndContract
          )}</div>`;
        },
      },
      {
        name: "Status Kontrak",
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div
          class="rounded-full px-2 py-0 text-center ${this.extractColor(item)}"
        ><div class="overflow-ellipsis text-left capitalize">${
          item.partnerSubconsoleContractStatus
        }</div></div>`;
        },
      },
      {
        name: "Status Partner",
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: any) => {
          if (item.partnerSubconsoleStatus) {
            return `<div class="flex font-medium text-red-lp-300">
                    <img class="mr-1" src="${require("@/app/ui/assets/svg/ban.svg")}" /> Freezed
                  </div>`;
          }
          return `
          <div class="flex">-</div>`;
        },
      },
      {
        name: "Action",
        key: "button_column",
        styleHead: "w-32 text-left",
        styleButton: (item: any) => {
          return item.partnerSubconsoleStatus
            ? {
                outline: false,
                icon: "white-check",
                color: "red-lp-100",
                toolTip: "Unfreeze",
                small: false,
                clickFunction: (unfreeze: any) => {
                  this.partnerSelected = {
                    id: unfreeze.partnerSubconsoleId,
                    name: unfreeze.partnerSubconsoleName,
                    status: unfreeze.partnerSubconsoleStatus,
                    type: "sub-console",
                  };
                  this.freezeUnFreezeModal = true;
                },
              }
            : {
                outline: true,
                icon: "freeze",
                textColor: "white",
                color: "red-lp-100",
                toolTip: "Freeze",
                small: false,
                clickFunction: (freeze: any) => {
                  this.partnerSelected = {
                    id: freeze.partnerSubconsoleId,
                    name: freeze.partnerSubconsoleName,
                    status: freeze.partnerSubconsoleStatus,
                    type: "sub-console",
                  };
                  this.freezeUnFreezeModal = true;
                },
              };
        },
      },
    ];
  }

  get pagination() {
    return PartnerSubconsoleController.partnerSubconsoleList.pagination;
  }

  get partnerSubconsoleList() {
    return PartnerSubconsoleController.partnerSubconsoleList.data;
  }

  extractColor(item: any) {
    const color: any = {
      active: "bg-green-lp-300 text-green-lp-200",
      waiting: "bg-yellow-lp-200 text-yellow-lp-100",
      expired: "bg-red-300 text-red-lp-200",
    };

    return (
      color[item.partnerSubconsoleContractStatus.toLowerCase()] ??
      "bg-yellow-lp-100 text-yellow-200"
    );
  }

  onClickRow(item: any) {
    // to do
    this.$router.push(
      `/partner/sub-consolidator/detail/${item.partnerSubconsoleId}?params=${item.partnerSubconsoleName}`
    );
  }
  goToCreate() {
    this.$router.push("/partner/sub-consolidator/add");
  }

  // freeze un freeze
  freezeUnFreezeModal = false;
  partnerSelected: any = "";
}
