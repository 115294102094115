
import { Options, Vue } from "vue-class-component";
import EmptyState from "@/app/ui/components/empty-list/index.vue";
import AdvancedFilter from "@/app/ui/views/sales/partner-management/advanced-filter.vue";
import { HubController } from "@/app/ui/controllers/HubController";
import { checkRolePermission } from "@/app/infrastructures/misc/Utils";
import { PARTNER_MANAGEMENT } from "@/app/infrastructures/misc/RolePermission";

@Options({
  components: {
    EmptyState,
    AdvancedFilter
  },
  computed: {
    isDetailAble() {
      return checkRolePermission(PARTNER_MANAGEMENT.DETAIL);
    },
    isCreateAble() {
      return checkRolePermission(PARTNER_MANAGEMENT.CREATE);
    }
  }
})
export default class Hub extends Vue {
  mounted() {
    HubController.initDataTable();
  }
  onRequest() {
    HubController.fetchDataTable();
  }
  requestFilter() {
    HubController.setFirstPage();
    HubController.fetchDataTable();
  }
  goToCreate() {
    this.$router.push("/partner/hub/add");
  }
  get dataTable() {
    return HubController.hubList.data;
  }
  // Table
  columns = [
    {
      name: "No.",
      styleHead: "w-24 text-left whitespace-no-wrap",
      render: (item: any, index: number) => {
        return `<div class='text-left text-red-lp-200 flex justify-center'>${index +
          1 +
          this.pagination.limit * (this.pagination.page - 1)}</div>`;
      },
    },
    {
      name: "Kode Hub",
      styleHead: "w-40 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class="overflow-ellipsis text-black-lp-300">${item.hubCode}</div>`;
      },
    },
    {
      name: "Nama Hub",
      styleHead: "w-56 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class="overflow-ellipsis text-black-lp-300">${item.hubName}</div>`;
      },
    },
    {
      name: "Consolidator",
      styleHead: "w-40 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class='flex text-black-lp-300'>${item.consolidator}</div>`;
      }
    },
    {
      name: "Kota Hub",
      styleHead: "w-40 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class="text-black-lp-300">${item.originCity}</div>`;
      },
    },
    {
      name: "Status",
      styleHead: "w-40 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div
          class="rounded-full px-2 py-0 text-center ${this.extractColor(item)}"
        ><div class="overflow-ellipsis text-left capitalize">${
          item.status === "ACTIVE" ? "Aktif" : "Tidak Aktif"
        }</div></div>`;
      },
    }
  ];
  onClickRow(item: any) {
    // to do
    this.$router.push(`/partner/hub/detail/${item.hubId}?params=${item.hubName}`);
  }
  extractColor(item: any) {
    const color: any = {
      active: "bg-green-lp-300 text-green-lp-200",
      inactive: "bg-red-300 text-red-lp-200",
    };

    return color[item.status?.toLowerCase()];
  }
  // Pagination Table
  get pagination() {
    return HubController.hubList.pagination;
  }
  //Loading
  get isLoading() {
    return HubController.isLoading;
  }
  get advancedFilter() {
    return HubController.filterHub;
  }
  onUpdateAdvancedFilter(value: any) {
    HubController.setFilterHub(value);
  }
  //Filter Search
  onSearch(value: string) {
    HubController.searchAction(value);
  }
  get searchValue() {
    return HubController.search;
  }
  clearSearch() {
    HubController.clear();
  }
  //Error
  get isError() {
    return HubController.isError;
  }
}
