
import { Options, Vue, prop } from "vue-class-component";
import Tooltip from "@/app/ui/components/tooltip/index.vue";
import { BookingController } from "@/app/ui/controllers/BookingController";
import { UploadHydraFileRequest, DeleteHydraFileRequest } from "@/data/payload/api/BookingRequest";
import { UploadMultiple } from "@/domain/entities/MainApp";

class Props {
  photos = prop<string[]>({
    default: [],
    type: Array
  });
}

const seedImages = new Array(15).fill('');

@Options({
    emits: ["updateFiles", "setLoadingUpload"],
    components: {
        Tooltip
    }
})
export default class FileAttachment extends Vue.with(Props) {
    get proofPhotos() {
        const labels = [];
        let i = 1;

        while(i <= 15) {
            labels.push(`${this.$t('partnerManagement.label.attachment')} ${i}`);
            i++;
        }
        
        return labels;
    }

    attachmentImage: any = [];
    storageImages: any = seedImages.map((item: string, index: number) => this.photos[index]);
    isLoading = false;
    isLoadingDelete = false;
    isError = false;
    errorMessage = "";

    async upload(params: UploadMultiple) {
        this.isLoading = true;
        this.setLoadingUpload(true);
        this.isError = false;
        this.errorMessage = "";
        try {
            if (params.file) {
                const fileLink = await BookingController.uploadHydraFile(new UploadHydraFileRequest({
                    attachFile: params.file,
                    folderPath: "pos/"
                }))
                this.storageImages[params.index] = fileLink.data;
                this.updateFiles();
            }
        } catch (err) {
            this.isError = true;
            this.errorMessage = this.$t('partnerManagement.error.uploadFile', { value: params.index + 1 })
        }
        this.isLoading = false;
        this.setLoadingUpload(false);
    }

    async remove(index: number) {
        this.isLoadingDelete = true;
        this.setLoadingUpload(true);
        this.isError = false;
        this.errorMessage = "";
        try {
            const del = await BookingController.deleteHydraFile(new DeleteHydraFileRequest({
                attachFiles: [this.storageImages[index]]
            }))
            const statusDel = del.data[this.storageImages[index]];
            statusDel ? this.deleteFiles(index) : this.erroMessageDelete(index);
        } catch (err) {
            this.erroMessageDelete(index);
        }
        this.isLoadingDelete = false;
        this.setLoadingUpload(false);
    }

    updateFiles() {
        this.$emit("updateFiles", this.storageImages);
    }

    setLoadingUpload(status: boolean) {
        console.log(status)
        this.$emit("setLoadingUpload", status);
    }

    deleteFiles(index: number) {
        this.storageImages[index] = "";
        this.updateFiles();
    }

    erroMessageDelete(index: number) {
        this.deleteFiles(index);
    }

    get validateLimitUpload() {
        const images = this.storageImages.filter((item: string) => item);
        return images.length > 0 && images.length < 3;
    }
}
