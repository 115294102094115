
import { Vue, Options, prop } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";

class Props {
  detailData = prop<any>({
    type: Object,
    required: true,
  });
}

@Options({
  components: {
    Title,
  },
  props: {
    detailData: {
      type: Object,
      required: true,
    },
  },
})
export default class DetailContractInformation extends Vue.with(Props) {
  photoPreview = false;
  photo = "";
  onPreview(photo: string) {
    this.photo = photo;
    this.photoPreview = true;
  }

  get photoPreviews() {
    return this.detailData.partnerPosAttachFiles?.map((item: any) => {
      return {
        image: item,
        url: item,
      };
    });
  }

  get filesView() {
    const fileMap = this.detailData.partnerPosAttachFiles.map((item: string) => {
      const formatSplited = item.split(".")
      return {
        url: item,
        type: formatSplited[formatSplited.length - 1]
      }
    })

    return fileMap;
  }
}
