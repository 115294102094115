
import { Vue, Options, prop } from "vue-class-component";
import EmptyState from "@/app/ui/components/empty-list/index.vue";
import { PosController } from "@/app/ui/controllers/PosController";
import checkRolePermission from "@/app/infrastructures/misc/common-library/CheckRolePermission";
import ellipsisString from "@/app/infrastructures/misc/common-library/EllipsisString";
import formatPrice from "@/app/infrastructures/misc/common-library/FormatPrice";
import { PosData, PosParam } from "@/domain/entities/Pos";
import {
  PARTNER_MANAGEMENT,
  POS_BRANCH,
} from "@/app/infrastructures/misc/RolePermission";
import FreezeUnfreezePopup from "@/app/ui/views/sales/partner-management/freeze-unfreeze-popup.vue";
import AdvancedFilter from "@/app/ui/views/sales/partner-management/advanced-filter.vue";
import { AccountController } from "@/app/ui/controllers/AccountController";

class Props {
  posParentId = prop<number>({
    type: Number,
    default: 0,
  });
  showHeader = prop<boolean>({
    type: Boolean,
    default: true,
  });
}

@Options({
  components: {
    EmptyState,
    FreezeUnfreezePopup,
    AdvancedFilter,
  },
  computed: {
    isDetailAble() {
      return (
        checkRolePermission(PARTNER_MANAGEMENT.DETAIL) ||
        checkRolePermission(POS_BRANCH.DETAIL)
      );
    },
    isCreateAble() {
      return checkRolePermission(PARTNER_MANAGEMENT.CREATE);
    },
    isActionAble() {
      return checkRolePermission(PARTNER_MANAGEMENT.EDIT);
    },
  },
})
export default class PartnerPos extends Vue.with(Props) {
  mounted() {
    PosController.initPosList(
      new PosParam({
        posParentId: this.posParentId || this.posId,
      })
    );
  }

  get posId() {
    return AccountController.accountData.account_type_detail.id;
  }

  get advancedFilter() {
    return PosController.advancedFilter;
  }
  onUpdateAdvancedFilter(value: any) {
    PosController.initPagination()
    PosController.setAdvancedFilter(value);
  }

  // Search
  onSearch(value: string) {
    PosController.initPagination()
    PosController.setSearch(value);
    PosController.fetchPostList(
      new PosParam({
        posParentId: this.posParentId || this.posId,
      })
    );
  }

  clearSearch() {
    PosController.initPagination()
    PosController.setSearch("");
    PosController.fetchPostList(
      new PosParam({
        posParentId: this.posParentId || this.posId,
      })
    );
  }

  get searchValue() {
    return PosController.search;
  }

  // create pos
  goToCreate() {
    this.$router.push("/partner/pos/add");
  }

  // table

  get pagination() {
    return PosController.pagination;
  }

  get isLoading() {
    return PosController.isLoading;
  }

  get isError() {
    return PosController.isError;
  }

  get errorCause() {
    return PosController.errorCause;
  }

  get firstRequest() {
    return PosController.firstRequest;
  }

  get dataTable() {
    return PosController.posList.data;
  }

  get columns() {
    const columns: Array<any> = [
      {
        name: "No.",
        styleHead: "w-12 text-left whitespace-no-wrap",
        render: (item: PosData, index: number) => {
          return `<div class="overflow-ellipsis text-left">${
            index + 1 + this.pagination.limit * (this.pagination.page - 1)
          }</div>`;
        },
      },
      {
        name: "Kode POS",
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: PosData) => {
          return `<div class="overflow-ellipsis text-left">${item.code}</div>`;
        },
      },
      {
        name: "Nama POS",
        styleHead: "w-64 text-left whitespace-no-wrap",
        render: (item: PosData) => {
          return `<div class="overflow-ellipsis text-left">${ellipsisString(
            item.name,
            80
          )}</div>`;
        },
      },
      {
        name: "Tipe POS",
        styleHead: "w-64 text-left whitespace-no-wrap",
        render: (item: PosData) => {
          return `<div class="overflow-ellipsis text-left">${item.posType}</div>`;
        },
      },
      {
        name: "Kecamatan, Kota",
        styleHead: "w-48 text-left whitespace-no-wrap",
        render: (item: PosData) => {
          return `<div class="overflow-ellipsis text-left">${
            item.partnerLocation.district.name.split(",")[0]
          }, ${item.partnerLocation.city.name}</div>`;
        },
      },
      {
        name: "Tipe Partner Area",
        styleHead: "w-48 text-left whitespace-no-wrap",
        render: (item: PosData) => {
          return `<div class="overflow-ellipsis text-left">${
            item.parentType === "console" ? "Consolidator" : "Subconsolidator"
          }</div>`;
        },
      },
      {
        name: "Nama Partner Area",
        styleHead: "w-64 text-left whitespace-no-wrap",
        render: (item: PosData) => {
          return `<div class="overflow-ellipsis text-left">${ellipsisString(
            item.parentName,
            80
          )}</div>`;
        },
      },
      {
        name: "Saldo Tersedia",
        styleHead: "w-48 text-left whitespace-no-wrap",
        render: (item: PosData) => {
          return `<div class="overflow-ellipsis text-left">${formatPrice(
            item.balance
          )}</div>`;
        },
      },
      {
        name: "Registrasi No. Rekening",
        styleHead: "w-48 text-left whitespace-no-wrap",
        render: (item: PosData) => {
          return `<div class="overflow-ellipsis text-left">${
            item.isRegistry ? "Sudah" : "Belum"
          }</div>`;
        },
      },
      {
        name: "Status Kontrak",
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: PosData) => {
          return `
            <div class="flex">
          <div
            class="rounded-full px-2 py-0 text-center ${this.extractColor(
              item
            )}"
          ><span class="capitalize">${item.contractStatus
            .toLowerCase()
            .replace(/[_-]+/g, " ")}</span></div></div>`;
        },
      },
      {
        name: "Status Partner",
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: PosData) => {
          if (item.isBanned) {
            return `<div class="flex font-medium text-red-lp-300">
                    <img class="mr-1" src="${require("@/app/ui/assets/svg/ban.svg")}" /> Freezed
                  </div>`;
          }
          return `
          <div class="flex">-</div>`;
        },
      },
    ];

    const columnActions = [
      {
        name: "Action",
        key: "button_column",
        styleHead: "w-32 text-left",
        styleButton: (item: PosData) => {
          if (item.isBanned) {
            return {
              icon: "check-circle-border-white",
              customClass: ``,
              toolTip: "Unfreeze",
              clickFunction: (unfreeze: any) => {
                this.partnerSelected = {
                  id: unfreeze.id,
                  name: unfreeze.name,
                  status: unfreeze.isBanned,
                  type: "pos",
                };
                this.freezeUnFreezeModal = true;
              },
            };
          }
          return {
            outline: true,
            class: "w-full",
            icon: "ban",
            customClass: ``,
            toolTip: "Freeze",
            clickFunction: (freeze: any) => {
              this.partnerSelected = {
                id: freeze.id,
                name: freeze.name,
                status: freeze.isBanned,
                type: "pos",
              };
              this.freezeUnFreezeModal = true;
            },
          };
        },
      },
    ];

    return this.posId ? columns : [...columns, ...columnActions];
  }

  extractColor(item: PosData) {
    const color: any = {
      active: "bg-green-lp-300 text-green-lp-200",
      waiting: "bg-yellow-lp-200 text-yellow-lp-100",
      expired: "bg-red-300 text-red-lp-200",
    };

    return (
      color[item.contractStatus.toLowerCase()] ??
      "bg-yellow-lp-100 text-yellow-lp-200"
    );
  }

  onRequest() {
    PosController.fetchPostList(
      new PosParam({
        posParentId: this.posParentId || this.posId,
      })
    );
  }

  goToDetail(item: any) {
    const path = this.posId ? "/pos-cabang/detail/" : "/partner/pos/detail/";
    this.$router.push(`${path}${item.id}?params=${item.name}`);
  }

  // freeze un freeze
  freezeUnFreezeModal = false;
  partnerSelected: any = "";
}
