
import { Options, Vue } from "vue-class-component";
import { PartnerController } from "@/app/ui/controllers/PartnerController";
import { maskBankAccountName } from "@/app/infrastructures/misc/Utils";
import { BeneficiaryAccountController } from "@/app/ui/controllers/BeneficiaryAccountController";
import { ValidateBankAccountApiRequest } from "@/data/payload/api/BeneficiaryApiRequest";

@Options({
  components: {},
  props: {
    formType: {
      default: "add",
      type: String
    }
  }
})
export default class ModalAddBank extends Vue {
  props: any = this.$props;
  mounted() {
    PartnerController.getListPartnerBank();
  }
  // add bank midtrans enhancement
  get showAddBank() {
    return PartnerController.showAddBank;
  }
  onShowAddBank(val: boolean) {
    PartnerController.setShowAddBank(val);
  }

  // form
  form = {
    bank: {
      bankLogo: "",
      accountName: "",
      bankName: "" as any,
      accountNo: "",
      accountEmail: ""
    }
  };
  get formAdd() {
    return PartnerController.formAdd;
  }
  get bankDetail() {
    return BeneficiaryAccountController.beneficiaryDetail;
  }

  resetBank() {
    this.allowSave = false;
    this.form.bank.accountName = "";
    this.form.bank.accountNo = "";
    this.handleError();
  }

  get isDisabled() {
    return !this.form.bank.bankName;
  }

  allowValidate = false;

  get isDisabledValidate() {
    return (
      !this.form.bank.bankName ||
      !this.form.bank.accountNo ||
      !this.allowValidate
    );
  }

  allowSave = false;

  get isDisabledSave() {
    return !this.allowSave || this.error.bankEmail;
  }

  get maskedBankAccountName() {
    return maskBankAccountName(this.form.bank.accountName);
  }

  formatNumber(value: string) {
    return value.replace(/[\D]+/g, "");
  }

  // bank
  get bankList() {
    return PartnerController.partnerBank.map(bank => {
      return { name: bank.bankName, value: bank.bankCode };
    });
  }
  fetchListBank() {
    PartnerController.getListPartnerBank();
  }
  get loadingBank() {
    return PartnerController.isLoadingBank;
  }

  async onValidateBank() {
    this.handleError();
    if (this.isDisabledValidate || this.isLoadingValidationBank) {
      return;
    }
    const result: any = await BeneficiaryAccountController.validateBankAccount(
      new ValidateBankAccountApiRequest(
        this.form.bank.bankName.value,
        this.form.bank.accountNo
      )
    );
    if (result?.accountName) {
      this.form.bank.accountName = result.accountName || "";
      this.allowSave = true;
      this.allowValidate = false;
    } else {
      this.error.accountBankNoMessage =
        result?.response?.data?.message.id || "";
    }
  }

  onAddBank() {
    this.onShowAddBank(false);
    if (this.isDisabledSave) {
      return;
    }
    if (this.props.formType === "edit") {
      this.bankDetail.bankAccountLogo = this.form.bank.bankLogo;
      this.bankDetail.bankAccountName = this.form.bank.accountName;
      this.bankDetail.bankAccountLabel = this.form.bank.bankName.name;
      this.bankDetail.bankName = this.form.bank.bankName.value;
      this.bankDetail.bankAccountNumber = this.form.bank.accountNo;
      this.bankDetail.bankAccountEmail = this.form.bank.accountEmail;
      return;
    }
    this.formAdd.bank = {
      ...this.form.bank,
      bankName: this.form.bank.bankName.name,
      bankCode: this.form.bank.bankName.value
    };
  }

  // error
  error = {
    bankEmail: false,
    accountBankNo: false,
    accountBankNoMessage: ""
  };

  validateEmailBank(value: string) {
    this.error.bankEmail = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) && !!value;
  }

  handleError() {
    BeneficiaryAccountController.setErrorCauseValidationBank("");
    BeneficiaryAccountController.setIsErrorValidationBank(false);
  }

  // account bank no
  validateAccountNo(value: string) {
    this.handleError();
    this.allowSave = false;
    this.allowValidate = true;
    this.error.accountBankNo = !/^\d+$/.test(value);
  }

  get errorCauseValidationBank() {
    return BeneficiaryAccountController.errorCauseValidationBank;
  }
  get isErrorValidationBank() {
    return BeneficiaryAccountController.isErrorValidationBank;
  }
  get isLoadingValidationBank() {
    return BeneficiaryAccountController.isLoadingValidationBank;
  }
}
