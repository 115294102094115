
import { Options, Vue } from "vue-class-component";
import { PARTNER_MANAGEMENT } from "@/app/infrastructures/misc/RolePermission";
import { ConsolidatorController } from "@/app/ui/controllers/ConsolidatorController";
import formatDateWithoutTime from "@/app/infrastructures/misc/common-library/FormatDateWithoutTime";
import checkRolePermission from "@/app/infrastructures/misc/common-library/CheckRolePermission";
import ellipsisString from "@/app/infrastructures/misc/common-library/EllipsisString";
import EmptyState from "@/app/ui/components/empty-list/index.vue";
import AdvancedFilter from "@/app/ui/views/sales/partner-management/advanced-filter.vue";
import FreezeUnfreezePopup from "@/app/ui/views/sales/partner-management/freeze-unfreeze-popup.vue";

@Options({
  components: {
    EmptyState,
    AdvancedFilter,
    FreezeUnfreezePopup,
  },
  computed: {
    isDetailAble() {
      return checkRolePermission(PARTNER_MANAGEMENT.DETAIL);
    },
    isCreateAble() {
      return checkRolePermission(PARTNER_MANAGEMENT.CREATE);
    },
    isActionAble() {
      return checkRolePermission(PARTNER_MANAGEMENT.EDIT);
    },
  },
})
export default class PartnerConsolidator extends Vue {
  mounted() {
    ConsolidatorController.initDataTable();
  }

  onRequest() {
    ConsolidatorController.fetchDataTable();
  }

  get firstRequest() {
    return ConsolidatorController.firstRequest;
  }

  get dataTable() {
    return ConsolidatorController.consolidatorData.data;
  }

  get advancedFilter() {
    return ConsolidatorController.filterConsolidator;
  }
  onUpdateAdvancedFilter(value: any) {
    ConsolidatorController.setFilterConsolidator(value);
  }

  //Filter Search
  onSearch(value: string) {
    ConsolidatorController.searchAction(value);
  }
  get searchValue() {
    return ConsolidatorController.search;
  }
  clearSearch() {
    ConsolidatorController.clear();
  }

  // Table
  columns = [
    {
      name: "No.",
      styleHead: "w-24 text-left whitespace-no-wrap",
      render: (item: any, index: number) => {
        return `<div class='text-left text-red-lp-200 flex justify-center'>${
          index + 1
        }</div>`;
      },
    },
    {
      name: "Kode Consolidator",
      styleHead: "w-56 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class="overflow-ellipsis text-black-lp-300">${item.code}</div>`;
      },
    },
    {
      name: "Nama Consolidator",
      styleHead: "w-64 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class="overflow-ellipsis text-black-lp-300">${ellipsisString(
          item.name,
          80
        )}</div>`;
      },
    },
    {
      name: "Total Kota",
      styleHead: "w-40 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class='flex text-black-lp-300'>${item.partnerLocationList.length}</div>`;
      },
      tooltipHeader: () => {
        return `<div class="text-black-lp-300 font-semibold text-12px">Total Kota</div><div class='mt-2 text-gray-lp-500 text-12px'>
                  Total Kota adalah jumlah kota yang ditangani oleh Partner.
                </div>`;
      },
    },
    {
      name: "Kota Asal",
      styleHead: "w-40 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class="text-black-lp-300">${item.partnerLocation.city.name}</div>`;
      },
    },
    {
      name: "Akhir Kontrak",
      styleHead: "w-40 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<span class="text-black-lp-300">${formatDateWithoutTime(
          item.contractEnd
        )}</span>`;
      },
    },
    {
      name: "Status Kontrak",
      styleHead: "w-40 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div
          class="rounded-full px-2 py-0 text-center ${this.extractColor(item)}"
        ><div class="overflow-ellipsis text-left capitalize">${
          item.contractStatus
        }</div></div>`;
      },
    },
    {
      name: "Status Partner",
      styleHead: "w-40 text-left whitespace-no-wrap",
      render: (item: any) => {
        if (item.isBanned) {
          return `<div class="flex font-medium text-red-lp-300">
                    <img class="mr-1" src="${require("@/app/ui/assets/svg/ban.svg")}" /> Freezed
                  </div>`;
        }
        return `
          <div class="flex">-</div>`;
      },
    },
    {
      name: "Action",
      key: "button_column",
      styleHead: "w-32 text-left",
      styleButton: (item: any) => {
        if (item.isBanned) {
          return {
            icon: "check-circle-border-white",
            customClass: ``,
            toolTip: "Unfreeze",
            clickFunction: (unfreeze: any) => {
              this.partnerSelected = {
                id: unfreeze.id,
                name: unfreeze.name,
                status: unfreeze.isBanned,
                type: "console",
              };
              this.freezeUnFreezeModal = true;
            },
          };
        }
        return {
          outline: true,
          class: "w-full",
          icon: "ban",
          customClass: ``,
          toolTip: "Freeze",
          clickFunction: (freeze: any) => {
            this.partnerSelected = {
              id: freeze.id,
              name: freeze.name,
              status: freeze.isBanned,
              type: "console",
            };
            this.freezeUnFreezeModal = true;
          },
        };
      },
    },
  ];
  onClickRow(item: any) {
    // to do
    this.$router.push(
      `/partner/consolidator/detail/${item.id}?params=${item.name}`
    );
  }
  goAdd() {
    this.$router.push("/partner/consolidator/add");
  }

  extractColor(item: any) {
    const color: any = {
      active: "bg-green-lp-300 text-green-lp-200",
      waiting: "bg-yellow-lp-200 text-yellow-lp-100",
      expired: "bg-red-300 text-red-lp-200",
    };

    return (
      color[item.contractStatus.toLowerCase()] ??
      "bg-yellow-lp-100 text-yellow-200"
    );
  }

  // Pagination Table
  get pagination() {
    return ConsolidatorController.consolidatorData.pagination;
  }

  //Loading
  get isLoading() {
    return ConsolidatorController.isLoading;
  }

  //Error
  get isError() {
    return ConsolidatorController.isError;
  }

  //Error Cause
  get errorCause() {
    return ConsolidatorController.isErrorCause;
  }

  // freeze un freeze
  freezeUnFreezeModal = false;
  partnerSelected: any = "";
}
