const levelingPartner = (partnerPosTieringLevel: string) => {
    const levelData: any = {
        "bronze": {
          title: "Bronze",
          icon: "bronze",
          color: "yellow-lp-800"
        },
        "silver": {
          title: "Silver",
          icon: "silver",
          color: "gray-lp-600"
        },
        "gold": {
          title: "Gold",
          icon: "gold",
          color: "yellow-lp-1300"
        },
        "platinum": {
          title: "Platinum",
          icon: "platinum",
          color: "red-lp-1600"
        },
        "diamond": {
          title: "Diamond",
          icon: "diamond",
          color: "gray-lp-300"
        }
      }
  
      return levelData[partnerPosTieringLevel];
}

export default levelingPartner;