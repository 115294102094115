import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "pt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lp_input = _resolveComponent("lp-input")!
  const _component_Modal = _resolveComponent("Modal")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!

  return (_ctx.modelValue)
    ? (_openBlock(), _createBlock("div", _hoisted_1, [
        (_ctx.reasonModal)
          ? (_openBlock(), _createBlock(_component_Modal, {
              key: 0,
              title: "Konfirmasi Pembekuan",
              message: "Masukan alasan pembekuan partner. Selama pembekuan, partner tidak dapat melakukan aktivitas operasional.",
              image: "warning",
              isDisabled: _ctx.isDisabled,
              textSuccess: "Freeze",
              textCancel: "Kembali",
              class: "px-8",
              onClose: _ctx.onCloseReasonModal,
              onSubmit: _ctx.onSubmitReasonModal
            }, {
              custom: _withCtx(() => [
                _createVNode("div", _hoisted_2, [
                  _createVNode(_component_lp_input, {
                    modelValue: _ctx.partnerFreezeReason,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.partnerFreezeReason = $event)),
                    type: "textarea",
                    placeholder: "Masukan alasan pembekuan"
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _: 1
            }, 8, ["isDisabled", "onClose", "onSubmit"]))
          : _createCommentVNode("", true),
        (_ctx.confirmationModal)
          ? (_openBlock(), _createBlock(_component_Modal, {
              key: 1,
              onSubmit: _ctx.onSubmitConfirmationModal,
              onClose: _ctx.onCloseConfirmationModal,
              title: _ctx.titleConfirmationModal,
              message: _ctx.messageConfirmationModal,
              image: "are-you-sure",
              textSuccess: _ctx.buttonConfirmationModal,
              textCancel: "Kembali",
              class: "px-8"
            }, null, 8, ["onSubmit", "onClose", "title", "message", "textSuccess"]))
          : _createCommentVNode("", true),
        (_ctx.finishModal)
          ? (_openBlock(), _createBlock(_component_Modal, {
              key: 2,
              onSubmit: _ctx.onCloseFinishModal,
              title: _ctx.titleFinishModal,
              message: _ctx.messageFinishModal,
              image: "image-modal-success",
              textSuccess: "OK",
              class: "px-8"
            }, null, 8, ["onSubmit", "title", "message"]))
          : _createCommentVNode("", true),
        (_ctx.isError)
          ? (_openBlock(), _createBlock(_component_ErrorMessage, {
              key: 3,
              textButtonSuccess: "OK",
              errorTitle: _ctx.errorTitle,
              errorMessage: "Terjadi kesalahan pada system, silahkan<br>coba lagi nanti.",
              onTryAgain: _ctx.onCloseReasonModal,
              modal: ""
            }, null, 8, ["errorTitle", "onTryAgain"]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}