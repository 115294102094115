import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { Pagination } from "@/domain/entities/Pagination";
import { PartnerSubconsoleEntities } from "@/domain/entities/PartnerSubconsole";
import { container } from "tsyringe";
import { PartnerSubconsolePresenter } from "@/app/ui/presenters/PartnerSubconsolePresenter";

export interface PartnerSubconsoleState {
  isLoading: boolean;
  isError: boolean;
  errorCause: string;
}

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "partner-management-subconsole"
})
class PartnerSubconsoleStore extends VuexModule
  implements PartnerSubconsoleState {
  public isLoading = true;
  public isError = false;
  public errorCause = "";
  public filter = {
    search: "",
    district: ""
  };
  public advancedFilter = {
    statusContract: "",
    statusPartner: "",
    originCity: {
      name: "",
      code: ""
    }
  };
  public partnerSubconsoleList = new PartnerSubconsoleEntities(
    new Pagination(1, 10),
    []
  );
  public isConfirm = false;
  public isFirstRequest = true;

  @Action
  getPartnerSubconsoleList(params: {
    search: string;
    page: number;
    limit: number;
    city: string;
    district: string;
    partnerStatus: string;
    contractStatus: string;
  }) {
    this.setIsLoading(true);
    const presenter = container.resolve(PartnerSubconsolePresenter);
    presenter
      .getPartnerSubconsoleList(
        params.search,
        params.page,
        params.limit,
        true,
        false,
        params.city,
        params.district,
        params.partnerStatus,
        params.contractStatus
      )
      .then((res: PartnerSubconsoleEntities) => {
        this.setPartnerSubconsole(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
        this.setPartnerSubconsole(
          new PartnerSubconsoleEntities(new Pagination(0, 0), [])
        );
      })
      .finally(() => {
        this.setIsLoading(false);
      });
  }

  @Action
  public fetchPartnerSubconsoleList() {
    this.setFirstRequest(false);
    this.getPartnerSubconsoleList({
      search: this.filter.search,
      page: this.partnerSubconsoleList.pagination.page,
      limit: this.partnerSubconsoleList.pagination.limit,
      city: this.advancedFilter.originCity.code || "",
      district: this.filter.district,
      partnerStatus: this.advancedFilter.statusPartner,
      contractStatus: this.advancedFilter.statusContract
    });
  }

  @Action
  public initDataTable() {
    this.setFirstRequest(true);
    this.setSearch("");
    this.setDistrict("");
    this.setError(false);
    this.getPartnerSubconsoleList({
      search: "",
      page: 1,
      limit: 10,
      city: "",
      district: "",
      partnerStatus: "",
      contractStatus: ""
    });
  }

  @Action
  public setFirstPage() {
    this.partnerSubconsoleList.pagination.page = 1;
  }

  @Mutation
  private setPartnerSubconsole(data: PartnerSubconsoleEntities) {
    this.partnerSubconsoleList = data;
  }

  @Mutation
  public setConfirm(value: boolean) {
    this.isConfirm = value;
  }
  @Mutation
  public setFirstRequest(value: boolean) {
    this.isFirstRequest = value;
  }

  @Mutation
  public setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  @Action
  public setSearch(value: string) {
    this.filter.search = value;
  }

  @Action
  public async searchAction(value: string) {
    this.setSearch(value);
    this.setFirstPage();
    await this.fetchPartnerSubconsoleList();
  }

  @Mutation
  public setDistrict(value: any) {
    this.filter.district = value;
  }

  @Mutation
  public setError(value: boolean) {
    this.isError = value;
  }

  @Mutation
  public setErrorCause(value: string) {
    this.errorCause = value;
  }

  @Mutation
  public setAdvancedFilter(value: any) {
    this.advancedFilter = value;
  }
}

export const PartnerSubconsoleController = getModule(PartnerSubconsoleStore);
